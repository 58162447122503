import { resourceSchemaDefault } from "axios-rest-resource";
import resourceBuilder from "../base";

const { read } = resourceSchemaDefault;

export const productsResources = resourceBuilder.build("/products", {
  read: {
    ...read,
    url: "/allProducts",
  },
});
