import { Button, Modal } from "flowbite-react";

export function ConfirmActionModal({
  openModal,
  setOpenModal,
  onConfirm,
  onCancel,
  message,
  confirmLabel,
  cancelLabel,
}) {
  return (
    <>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {message}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={onConfirm}>
                {confirmLabel}
              </Button>
              <Button color="gray" onClick={onCancel}>
                {cancelLabel}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
