import NavBar from "../../components/navs/NavBar/NavBar";

const BasicDisplay = ({ children }) => {
  return (
    <main className="h-dvh bg-gray-100">
      <NavBar />
      <div className="m-4 md:!mx-16 md:!mt-10" id="main">
        <section className="pl-0 pr-0">{children}</section>
      </div>
    </main>
  );
};

export default BasicDisplay;
