import { channelsResources } from "../../../../resources"; // Adjust the import path as needed
import { useEffect, useState } from "react"; // Adjust the import path as needed

export const useChannel = (selectedProduct) => {
  const [channels, setChannels] = useState([]);
  const fetchChannels = async () => {
    try {
      const fetchParams = {
        params: {
          productSKU: selectedProduct?.sku,
        },
      };
      const loadedChannels = await channelsResources.read(fetchParams);
      const allChannelsOption = { slug: "All Channels", isAllChannels: true };
      const channelsData = [allChannelsOption, ...loadedChannels];
      setChannels(channelsData);
    } catch (error) {
      console.error("Error loading channels", error);
    }
  };

  useEffect(() => {
    if (!selectedProduct || !selectedProduct.sku) {
      setChannels([]);
      return;
    }
    fetchChannels();
    // eslint-disable-next-line
  }, [selectedProduct]);

  return { channels };
};
