import SearchBar from "./SearchBar/SearchBar";
import ChannelDropdown from "./ChannelDropdown/ChannelDropdown";
import ActionsMenu from "./ActionsMenu/ActionsMenu";
import ProductDropDown from "./ProductDropDown/ProductDropDown";
import { SelectedCounter } from "./SelectedCounter";

export function DevicesSearchHeader() {
  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center">
        <ProductDropDown />
        <ChannelDropdown />
        <SearchBar />
        <ActionsMenu />
      </div>

      <div className="mr-2 flex items-center">
        <SelectedCounter />
      </div>
    </div>
  );
}
