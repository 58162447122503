import React, { createContext, useContext, useEffect, useState } from "react";
import { useChannel } from "../hooks/useChannels";
import { useDevices } from "../hooks/useDevices";
import { useLoadedProducts } from "../../../stores/useLoadedProducts";
import { useQueryParams } from "../hooks/useQueryParams";

const SearchContext = createContext();

export const SearchContextProvider = ({ children }) => {
  const { queryParams, clearAllQueryParams } = useQueryParams();
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [searchTerm, setSearchTerm] = useState(queryParams?.searchTerm || "");
  const [selectedPage, setSelectedPage] = useState(0);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loadedChannelQuery, setLoadedChannelQuery] = useState(null);
  const [loadedProductQuery, setLoadedProductQuery] = useState(null);

  const { channels } = useChannel(selectedProduct);
  const { productsData } = useLoadedProducts();
  const { devices, isLoading, totalNumOfPages, fetchDevices, totalDevices } =
    useDevices(
      searchTerm,
      selectedChannel,
      selectedPage,
      selectedProduct,
      setSelectedPage,
      setSelectedDevices,
      setSelectedChannel
    );

  const handleLoadProductFromQuery = () => {
    if (!productsData || loadedProductQuery) return;
    const productQuery = queryParams?.productSku;
    if (productQuery) {
      const product = productsData.find(
        (product) => product.sku === productQuery
      );
      setLoadedProductQuery(true);
      setSelectedProduct(product);
    }
  };

  const handleLoadChannelFromQuery = () => {
    if (!channels || channels.length <= 0 || loadedChannelQuery) return;
    const channelQuery = queryParams?.channel;
    if (channelQuery) {
      const channel = channels.find(
        (product) => product?.id?.toString() === channelQuery
      );
      setLoadedChannelQuery(true);
      setSelectedChannel(channel);
    }
  };

  const clearSearchTerms = () => {
    setSearchTerm("");
    setSelectedChannel(null);
    setSelectedProduct(null);
    setSelectedDevices([]);
    setSelectedPage(0);
    clearAllQueryParams();
  };

  useEffect(() => {
    handleLoadProductFromQuery();
    handleLoadChannelFromQuery();
    // eslint-disable-next-line
  }, [productsData, channels]);

  return (
    <SearchContext.Provider
      value={{
        channels,
        devices,
        searchTerm,
        setSearchTerm,
        isLoading,
        setSelectedChannel,
        selectedChannel,
        totalNumOfPages,
        selectedPage,
        setSelectedPage,
        selectedDevices,
        setSelectedDevices,
        productsData,
        selectedProduct,
        setSelectedProduct,
        fetchDevices,
        totalDevices,
        clearSearchTerms,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
export const useSearch = () => useContext(SearchContext);
