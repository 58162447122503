import {
  OwlDropdown,
  OwlDropdownButton,
  OwlDropdownMenu,
  OwlDropdownMenuItem,
} from "@owl-labs/ui-components";
import { devicesResource } from "../../../../../resources";
import { useSearch } from "../../../context";
import { ConfirmActionModal } from "../../../../../components/modals/ConfirmAction";
import { useState } from "react";

const ActionsMenu = () => {
  const {
    searchTerm,
    selectedChannel,
    selectedPage,
    selectedDevices,
    fetchDevices,
    clearSearchTerms,
  } = useSearch();
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);

  const parseCsvDataToDownload = (csvData) => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "exportedDevices.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  const exportCsv = async () => {
    try {
      const csvData = await devicesResource.exportCsv({
        params: {
          query: searchTerm,
          channel: selectedChannel?.id,
          page: selectedPage,
        },
      });
      parseCsvDataToDownload(csvData);
    } catch (error) {
      console.error("Error loading devices", error);
    } finally {
    }
  };

  const deleteBatch = async () => {
    try {
      await devicesResource.deleteBatch({
        data: {
          deviceUUIDs: selectedDevices,
        },
      });
      fetchDevices();
    } catch (error) {
      console.error("Erraor loading devices", error);
    } finally {
      setIsConfirmDeleteModalOpen(false);
    }
  };

  const enabledBatchDelete = selectedDevices?.length > 0;

  return (
    <>
      <div className="hidden sm:block">
        <OwlDropdown className="ml-2">
          <OwlDropdownButton
            direction="left"
            name="Export devices dropdown"
            type="ellipsis"
          >
            <OwlDropdownMenu className="mt-10 w-[260px]">
              <OwlDropdownMenuItem
                menuItemText="Clear Search"
                onClick={clearSearchTerms}
                className="text-base"
              />
              <hr />
              <OwlDropdownMenuItem
                menuItemText="Export"
                onClick={exportCsv}
                className="text-base"
              />
              <hr />

              <OwlDropdownMenuItem
                menuItemText={`Delete (${selectedDevices?.length})`}
                onClick={() => {
                  enabledBatchDelete && setIsConfirmDeleteModalOpen(true);
                }}
                className={`${
                  !enabledBatchDelete &&
                  "pointer-events-none text-gray-600 text-opacity-50"
                } ${enabledBatchDelete && "text-red-600"} point text-base`}
              />
            </OwlDropdownMenu>
          </OwlDropdownButton>
        </OwlDropdown>
      </div>
      <ConfirmActionModal
        openModal={isConfirmDeleteModalOpen}
        setOpenModal={setIsConfirmDeleteModalOpen}
        message={`Are you sure you want to delete ${selectedDevices?.length} selected devices?`}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        onConfirm={deleteBatch}
        onCancel={() => {
          setIsConfirmDeleteModalOpen(false);
        }}
      />
    </>
  );
};
export default ActionsMenu;
