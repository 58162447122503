/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/
const weWerentAbleTo = (message) =>
  `Uh oh! We weren't able to ${message}. Please try again shortly.`;
const addAdmin = "Add Admin";
const addUser = "Add Member";

module.exports = {
  /* 01.General */
  "general.app-name": "Barn",
  "general.company-name": "Owl Labs",
  "general.cancel": "Cancel",
  "general.loading": "Loading...",
  "general.submit": "Submit",
  "general.join": "Join",
  "general.save": "Save",
  "general.continue": "Continue",
  "general.okay": "Okay",
  "general.all": "All",
  "general.close": "Close",
  "general.name": "Name",
  "general.coming-soon": "Coming soon!",
  "general.error": "Uh oh! Something went wrong. Please try again shortly.",
  "general.learn-more": "Learn More",
  "general.not-available": "Not Available",
  "general.no-continue": "No, Continue",
  "general.yes-cancel": "Yes, Cancel",
  "general.actions": "Actions",
  "general.more-options": "More Options",
  "general.skip-to-main": "Skip to main content",
  "general.back": "Back",
  "general.nevermind": "Nevermind",
  "general.confirm": "Confirm",
  "general.edit": "Edit",
  "products.meeting_owl": "Meeting Owl",
  "products.meeting_owl_pro": "Meeting Owl Pro",
  "products.perch": "Meeting HQ",
  "products.hummingbird": "Whiteboard Owl",
  "products.meeting_owl_3": "Meeting Owl 3",
  "products.owlbar": "Owl Bar",
  "products.meeting_owl_4_pro": "Meeting Owl 4 Pro",
  "products.session.whiteboard.meeting.owl": "Whiteboard Meeting Owl", //session returns this as Product Name for Whiteboard Owl
  "navbar.icons.account": "Account Dropdown",

  // Main App Error Boundary
  "error-boundary.title": "An Error Occurred",
  "error-boundary.description":
    "We've hit a technical snag. Click below to refresh and return to the dashboard.",
  "error-boundary.action": "If the issue persists, please ",
  "error-boundary.contact-support": "contact support",
  "error-boundary.refresh-return-to-dashboard": "Refresh & Return to Dashboard",

  // Google reCAPTCHA
  "recaptcha.protected": "This site is protected by reCAPTCHA and the Google ",
  "recaptcha.privacy-policy": "Privacy Policy",
  "recaptcha.and": " and ",
  "recaptcha.terms-of-service": "Terms of Service",
  "recaptcha.apply": " apply.",

  /* DEPRECATEED 02.User Login, Logout, Register */
  "user.login-title": "Login",
  "user.register": "Register",
  "user.forgot-password": "Forgot Password",
  "user.email": "E-mail",
  "user.password": "Password",
  "user.forgot-password-question": "Forget password?",
  "user.fullname": "Full Name",
  "user.login-button": "LOGIN",
  "user.register-button": "REGISTER",
  "user.reset-password-button": "RESET",
  "user.buy": "BUY",
  "user.username": "Username",

  /* 02.User Login and register */
  "login.token.header": "LOGGING IN...",
  "login.token.body": "Hold tight while we securely login to your account.",
  "login.token.missing":
    "Oops! There was an error with the login link token. Please login again.",
  "login.token.expired": "Oops! This link has expired. Please login again.",
  "login.header": "THE NEST",
  "login.body": "Owl Labs' personalized customer portal",
  "login.placeholder": "myemail@company.com",
  "login.signup.header": "Log Into Nest",
  "login.signup.body":
    "Enter the email address you used to register your Meeting Owl, or your email address with your organization's domain name and we will send you a login link into your email.",
  "login.button.get-access": "Send me a login link",
  "login.success.header": "Success",
  "login.success.body": "Thanks! An email has been sent to your inbox.",
  "login.error.invalid-email": "Email format is invalid - Please try again.",
  "login.error.contact-admin":
    "Please contact your administration to have your account created.",
  "login.error.no-owls":
    "We did not find any Meeting Owls with your email address. Please check that you've entered the correct email address, and that you have at least one registered Owl. If you believe this is incorrect, please contact us.",
  "login.oauth.failure": "Oops! We didn't find your login information.",
  "login.oauth.general-error":
    "We could not log you in with your chosen identity provider. Please try using email.",
  "login.error.invitation-needed-header": "This organization already exists",
  "login.error.invitation-needed":
    "To join an existing team, please contact your administrator and ask them to invite you!",
  "login.error.button.learn-more": "Learn more",
  "login.error.no-devices-found-header": "Unable To Login",
  "login.error.no-devices-found":
    "We are unable to log you into the Nest, as no devices were found registered to your email domain. A Meeting Owl device must be registered to your domain in order to access the Nest. Please contact support with any questions.",
  "login.error.inactive-user":
    "Your account is currently inactive. Please contact your team admin to reactivate your account.",
  "login.or": "OR",
  "login.by-using-the-nest": "By using the Nest you agree to our ",
  "login.privacy-policy": "Privacy Policy",
  "login.and": " and ",
  "login.terms-of-service": "Terms of Service",
  "login.dont-have-account": "Don't have an account?",
  "login.sign-up": "Sign Up",
  "login.go-back-to": "Go back to ",
  "login.sign-in": "Sign In",
  "login.sign-in-with-google": "Sign in with Google",
  "login.sign-in.meet-the-nest":
    "Meet the Nest, the home for your Meeting Owls.",
  "login.sign-in.multifactor-authentication": "Multifactor Authentication",
  "login.sign-in.mfa-enter-the-code":
    "Enter the code that was sent to the phone number you set up for your account.",
  "login.sign-in.please-confirm-email": "Please confirm your email address.",
  "login.sign-in.enter-code-emailed-to": "Enter the code that was emailed to: ",
  "login.sign-in.enter-code-sent-to": "Enter the code that was sent to: ",
  "login.sign-in.email-confirmed": "Your email address has been confirmed.",
  "login.return-to": "Return to ",
  "login.sign-in.mfa-first-sign-in":
    "Would you like to set up multifactor authentication?",
  "login.sign-in.add-phone":
    "Please enter the phone number you would like to associate with your account.",
  "login.code": "Code",
  "login.forgot-password.code": "Verification Code",
  "login.incorrect-code": "Incorrect code",
  "login.resend-code": "Resend code",
  "login.sign-in-skip-for-now": "Skip for now",
  "login.sign-in-skip-warning":
    "If you cancel now, multifactor authentification will not be set up for your account. You can set this up later in Account Settings.",
  "login.sign-in.phone": "Phone Number",
  "login.done": "Done",
  "login.modal-skip-mfa": "Skip multifactor authentication?",
  "login.modal-yes-skip": "Yes, Skip for now",
  "login.modal-set-up-auth": "Set Up Authentication",
  "signup.youve-been-invited": "You've been invited to ",
  "signup.create-nest-account": "Create a Nest account",
  "signup.thanks-for-creating-account": "Thanks for creating an account!",
  "signup.enter-code-emailed-to": "Please enter the code that was emailed to: ",
  "signup.account-successfully-created": "Account successfully created.",
  "login.email": "Email",
  "login.password": "Password",
  "login.forgot-password": "Forgot Password?",
  "login.show": "Show",
  "login.hide": "Hide",
  "login.password.must-contain": "Password must contain:",
  "login.a-lowercase-letter": "a lower case letter",
  "login.an-uppercase-letter": "an upper case letter",
  "login.a-special-character": "a special character",
  "login.a-number": "a number",
  "login.at-least-8-characters": "at least 8 characters",
  "login.banner.message":
    "We’ve made some upgrades! If you are a returning user, please click “Forgot Password?” to set your password. If you are a new user, please sign up.",
  "signup.your-name": "Your Name (Optional)",
  "signup.create-account": "Create Account",
  "forgot-password.forgot-your-password": "Forgot your password?",
  "forgot-password.check-your-email": "Check your email",
  "forgot-password.reset-your-password": "Reset your password",
  "forgot-password.password-successfully-reset": "Password Successfully Reset",
  "forgot-password.please-enter-email":
    "If the email address you entered has an account associated with it, you will receive an email with a code to reset your password. ",
  "forgot-password.account-disclaimer-and-code":
    "Please enter the verification code sent to your email address and type a new password for your account. If you did not receive a code, you may request a new one. Contact your administrator for assistance.",
  "forgot-password.enter-code-emailed-to":
    "Please enter the code that was emailed to: ",
  "forgot-password.enter-new-password": "Reset your password.",

  /* 03.Menu */
  "menu.dashboard": "Dashboard",
  "menu.devices": "Devices",
  "menu.analytics": "Analytics",
  "menu.checks": "Daily Checks",
  "menu.team": "Team",
  "menu.support": "Support",
  "menu.rooms": "Rooms",
  "menu.settings": "Settings",
  "menu.notifications": "Notifications",
  "menu.billing": "Billing",
  "menu.fleet-management": "Fleet Management",
  "menu.subscriptions": "Subscriptions",
  "menu.licenses": "Licenses",
  "menu.payment_method": "Payment Method",
  "menu.paid_invoices": "Paid Invoices",
  "menu.locations": "Locations",
  "menu.calendar_resources": "Calendar Integration",
  "menu.integrations": "Third-Party Add-ons",
  "menu.zoom_integration": "MHQ Zoom Integration",
  "menu.notifications.new-user": "New user account request: ",
  "menu.notifications.disconnected": "Disconnected ({value})",
  "menu.notifications.device_update_failure": `The update on {name} was unsuccessful; please make sure {name} is plugged in and connected to WiFi.`,
  "menu.notifications.software-update": `New Software Update {version} Available for {deviceType}`,
  "menu.notifications.software-update-reminder": `Reminder: scheduled software update to begin {targetedUpdateTime}`,
  "menu.notifications.software-update-summary": `Scheduled software update complete. {summary}`,
  "menu.notifications.software-update-finished-view-devices": ` View devices in the update`,
  "menu.notifications.software-update-now-summary": `Software update complete. {summary}`,
  "menu.notifications.software-update-view-scheduled-update": ` View scheduled update`,
  "menu.user-menu.user-menu": "User Menu",
  "menu.user-menu.account-settings": "Account Settings",
  "menu.user-menu.support-actions": "Support Actions",
  "menu.user-menu.switch-account": "Switch Account",
  "menu.user-menu.sign-out": "Sign Out",
  "menu.open-mobile-menu": "Open Mobile Menu",
  "menu.close-mobile-menu": "Close Mobile Menu",

  /* 04.Error Page */
  "pages.error-title": "Ooops... looks like an error occurred!",
  "pages.error-code": "Error code",
  "pages.go-back-home": "GO BACK HOME",
  "pages.error.maintenance":
    "The Nest is currently undergoing maintenance. We apologize for the inconvenience. Please visit our {site} for more information.",

  /* 05.Dashboard */
  "dashboard.quick-view.never-checked-in.title": "Never Checked In",
  "dashboard.quick-view.never-checked-in.detail":
    "Owls that have never checked in for updates.",
  "dashboard.quick-view.offline.title": "Offline",
  "dashboard.quick-view.offline.detail":
    "Owls unable to get automatic updates.",
  "dashboard.quick-view.update.title": "Outdated",
  "dashboard.quick-view.update.detail":
    "Owls that are on out-of-date software.",
  "dashboard.devices-have-not-checked-in": "devices have not checked in",
  "dashboard.all-devices-have-checked-in": "All devices have checked in",
  "dashboard.devices-have-software-updates-available":
    "devices have software updates available",
  "dashboard.devices-currently-updating": "devices currently updating",
  "dashboard.scheduled-software-update": "scheduled software update",
  "dashboard.scheduled-software-updates": "scheduled software updates",
  "dashboard.software-is-up-to-date-on-all-devices":
    "Software is up to date on all devices",
  "dashboard.view-button": "View",
  "dashboard.your-favorite-devices-header": "Your Favorite Devices",
  "dashboard.view-devices-button": "View Devices",
  "dashboard.your-favorite-devices-will-appear-here":
    "Your favorite devices will appear here",
  "dashboard.summary.summary-header": "Summary",
  "dashboard.favorites.remove-from-favorites": "Remove from favorites",
  "dashboard.favorites.add-to-favorites": "Add to favorites",

  /* 06.Devices Page */
  "devices.table.name": "Devices Table",
  "devices.name.label": "Name",
  "devices.device-type.label": "Device Type",
  "devices.serial-number.label": "Serial Number",
  "devices.sw-version.label": "SW Version",
  "devices.location.label": "Location",
  "devices.unnamed.label": "Unnamed",
  "devices.show-more": "Show more",
  "devices.show-all": "Show all",
  "devices.select": "Select all",
  "devices.deselect": "Deselect all",
  "device.detail.version": "Software Version",
  "device.detail.email": "Registered Email",
  "device.detail.iot": "IoT Status",
  "device.action.name": "Change name of device",
  "device.action.save": "Save",
  "device.detail.title": "Details",
  "device.detail.tab.title": "Device Details",
  "devices.action": "Action button",
  "devices.showing": "Showing",
  "devices.devices": "devices",
  "devices.found": "found",
  "devices.the-first": "the first",
  "devices.at-a-time": "at a time",
  "devices.filter": "Filters",
  "devices.filter.apply": "Apply Filters",
  "devices.filter.clear-all": "Clear All",
  "devices.n-a": "N/A",
  "devices.unknown": "(Unknown)",
  "devices.search.title": "No results found!",
  "devices.search.description": "Try adjusting your search term or filter.",
  "devices.close": "Close",
  "devices.refresh": "Refresh",
  "devices.updateSoftware.title": "Update Software",
  "devices.updateSoftware.continue": "Continue",
  "devices.updateSoftware.body":
    "You are about to update the software of the following devices:",
  "devices.cantUpdateSoftware.body":
    "Software on the following device(s) cannot be updated:",
  "devices.updateSoftware.already-up-to-date":
    "Software on selected device(s) up-to-date.",

  "devices.showing.count": "Showing {count} devices",
  "devices.unnamed": "Unnamed Device",
  "devices.sub-header.all":
    "All {count} devices registered to this organization",
  "devices.sub-header.never-checked-in":
    "{count} devices have never checked in",
  "devices.sub-header.offline":
    "{count} Meeting Owls have not connected to wifi in a while",
  "devices.sub-header.upgrade":
    "{count} Meeting Owls that need software upgrades",
  "devices.sub-header.online": "{count} Meeting Owls are up-to-date and online",
  "devices.sub-header.disconnected":
    "{count} Owls set up for IoT, but currently offline",
  "devices.sub-header.connected": "{count} Owls are connected over IoT",
  "devices.sub-header.meeting_connected":
    "{count} Meeting Owls are in a meeting",
  "device.never-checked-in": "Never",
  "device.actions.edit": "Edit",
  "devices.buttons.transfer": "Claim Owl",
  "devices.buttons.export": "Export",
  "devices.transfer.header": "Add an Owl to your account",
  "devices.transfer.body":
    "To add or find a missing Owl device, enter its serial number below.",
  "devices.transfer.body.not-found":
    "Uh oh! We couldn't find a device with that information. Please double check the serial number and try again.",
  "devices.transfer.body.pending-transfer":
    "There is already a pending transfer request sent to the owner of this device. If you need additional support, please contact the Owl Labs team.",
  "devices.transfer.body.start-transfer":
    "This owl is currently registered to a different organization. Would you like to request the current owner to transfer this owl to your organization?",
  "devices.transfer.body.initiate-transfer": "Initiate Transfer",
  "devices.transfer.start-transfer.duplicate":
    "Duplicate devices found. Please double check the serial number and try again. If the issue persists, please contact support for assistance.",
  "devices.transfer.start-transfer.success":
    "If the owner confirms, the device will be added to your account.",
  "devices.link-to-device-details-page-for":
    ", Link to device details page for ",

  "device.status.label": "Status",
  "device.status.device-is": "Device is ",
  "device.status.device-has": "Device has ",
  "device.status.device-status-is": "Device status is ",
  "device.status.all.filter-label": "All",
  "device.status.never-checked-in.filter-label": "Never Checked In",
  "device.status.never-checked-in.label": "Never Checked In",
  "device.status.never-checked-in.tooltip":
    "Owls check in periodically for updates. Ensure your Owl is plugged in to power and connected to WiFi.",
  "device.status.offline.filter-label": "Offline",
  "device.status.offline.label": "Offline",
  "device.status.offline.tooltip":
    "Last seen {when}. Make sure this device is powered on and connected to WiFi.",
  "device.status.mhq-offline.tooltip":
    "Make sure this device is powered on and connected to WiFi.",
  "device.status.connected.filter-label": "Connected",
  "device.status.connected.label": "Connected",
  "device.status.connected.tooltip": "This device is connected over IoT",
  "device.status.disconnected.filter-label": "Disconnected",
  "device.status.disconnected.label": "Disconnected",
  "device.status.disconnected.tooltip":
    "This device is set up for IoT, but currently offline",
  "device.status.meeting_connected.label": "In A Meeting",
  "device.status.meeting_connected.tooltip": "This device is in a meeting",
  "device.status.meeting_connected.filter-label": "In A Meeting",
  "device.status.meeting_disconnected.label": "In A Meeting",
  "device.status.meeting_disconnected.tooltip":
    "This device is paired in a meeting and currently offline.",
  "device.status.meeting_disconnected.filter-label": "In A Meeting",
  "device.status.online.label": "Online",
  "device.status.updating.label": "Updating",
  "device.status.updating.tooltip": "This device is currently updating.",
  "device.status.online.filter-label": "Online",
  "device.status.online.tooltip":
    "This device is connected and working as expected.",
  "device.status.unknown.filter-label": "Unknown",
  "device.status.unknown.label": "Unknown",
  "device.status.up-to-date.label": "Up-to-date",
  "device.status.no-mhq-software-update.label":
    "Unable to update Meeting HQ in the Nest",

  "device.info.software-upgrade-available": "Software upgrade available.",
  "device.info.software-update-available": "Software update available.",
  "device.software-upgrade": "SW update icon",
  "device.location.label": "GeoLocation",
  "device.location.all": "All",
  "device.error.fetch": weWerentAbleTo("find your devices"),
  "device.success.name-update": "Name has been successfully changed.",
  "device.error.name-update": weWerentAbleTo("update the name"),
  "device.checkin.ota.success":
    "Software update underway! This update may take up to 15 minutes.",
  "device.checkin.ota.error":
    "Software update unsuccessful. We were not able to establish a connection to the Owl.",
  "device.filter.export-all": "Export List of Devices",
  "device.filter.export-selected": "Export List of Selected Devices",
  "device.filter.only.update": "Show devices that need an update",
  "device.filter.favorites": "Show favorite devices",
  "device.update.failure":
    "Sorry, something went wrong and we can't process your request",
  "device.reboot.success":
    "Device reboot has successfully started. Your device will be back online in a couple of minutes.",
  "device.reboot.failure": "Device reboot failed. Try again in a few minutes.",
  "device.reboot": "Reboot Device",
  "device.reboot.lower": "Reboot device",
  "device.reboot.initiating": "Initiating device reboot",
  "device.reboot-in-meeting.modal":
    "This Owl is in a meeting. Are you sure you want to reboot your device? This may take several seconds and your Owl will not be usable during that time.",
  "device.reboot.modal":
    "Are you sure you want to reboot your device? This may take several seconds.",
  "device.detail.back-to-devices": " Back",
  "device.detail.update-software-version": "Update Software Version",
  "device.detail.no-updates-for-your-owl":
    "There are no updates for your Owl at this time.",
  "device.view-analytics": "View Analytics",
  "device.transfer-device-ownership": "Transfer device ownership",

  "device.network-settings-tab.title": "Network Settings",
  "device.network-settings-tab.mac-address": "MAC Address: ",
  "device.network-settings-tab.iot-status": "IoT Status: ",
  "device.network-settings-tab.error-boundary":
    "There was an error getting network settings. Please try again shortly. If the issue persists, please contact support.",
  "device.network-settings-tab.information-icon-tooltip":
    "Device {tense} {status}, displaying most recent network connections",

  "device.default-settings-tab.video-title": "Video Settings",
  "device.default-settings-tab.lock-and-zoom": "Lock and Zoom: ",
  "device.default-settings-tab.vertically-flip-owl": "Vertically Flip Video: ",
  "device.default-settings-tab.auto-frame-everyone": "Auto Frame Everyone: ",
  "device.default-settings-tab.presenter-enhance": "Presenter Enhance: ",
  "device.default-settings-tab.360-pano": "360 Pano: ",
  "device.default-settings-tab.advanced-audio-settings-title": "Audio Settings",
  "device.default-settings-tab.noise-reduction": "Noise Reduction: ",
  "device.default-settings-tab.high": "High",
  "device.default-settings-tab.low": "Low",
  "device.default-settings-tab.off": "Off",
  "device.default-settings-tab.minimal": "Minimal",
  "device.default-settings-tab.default": "Default",
  "device.default-settings-tab.moderate": "Moderate",
  "device.default-settings-tab.aggressive": "Aggressive",
  "device.default-settings-tab.double-talk": "Double Talk: ",
  "device.default-settings-tab.owl-passcode-title": "Bluetooth Passcode",
  "device.default-settings-tab.owl-passcode": "Bluetooth Passcode: ",
  "device.analytics-tab.device-analytics": "Device Analytics",
  "device.analytics-tab.analytics-for": "Analytics for ",
  "device.analytics-tab.no-usage-data":
    "No usage data is available for your Owl.",
  "device.analytics-tab.contact-admin":
    "To change the setting, please contact your Owl administrator.",
  "device.detail.change-device-name-modal.title": "Change Device Name",
  "device.detail.change-device-name-modal.body": "Device Name",
  "device.detail.change-device-name-modal.error-short":
    "That name is too short.",
  "device.detail.change-device-name-modal.error-long": "That name is too long.",
  "device.table-edit-name": "Edit Device Name",
  "device.table-issue-commands": "Issue Commands",
  "device.no-updates": "There are no updates for your Owl at this time.",
  "device.update-software": "Update Software Version",

  "rooms.success.update_site": "Location has been successfully changed.",
  "rooms.success.update": "Room has been successfully changed.",
  "rooms.success.create": "Room has been successfully created.",
  "rooms.success.delete": "Room has been successfully deleted.",
  "rooms.error.update": "Could not change room.",
  "rooms.error.create": "Could not create room.",
  "rooms.error.delete": "Could not delete room.",
  "rooms.error.fetch": "Could not load your conference rooms.",
  "rooms.add_room": "Add Room",
  "rooms.add_location": "Add Location",
  "rooms.cancel": "Cancel",
  "rooms.submit": "Submit",
  "rooms.error.resource_fetch": "Could not load calendar resources",
  "rooms.error.resource_sync": "Could not sync your calendar resources.",
  "rooms.button.refresh_sync": "Refresh Calendar Sync",
  "rooms.integration.already_installed_findaroom":
    "Find-A-Room is already installed on your account.",
  "rooms.integration.error.findaroom":
    "We could not install Find-A-Room for you.",
  "rooms.rooms-table.room-name": "Room Name",
  "rooms.rooms-table.room-type": "Room Type",
  "rooms.rooms-table.capacity": "Capacity",
  "rooms.rooms-table.bookable": "Bookable",
  "rooms.rooms-table.devices": "Devices",
  "rooms.rooms-table.calendar": "Calendar",
  "rooms.rooms-table.actions": "Actions",
  "rooms.locations-table.number-of-rooms": "# of Rooms",
  "rooms.locations-table.location-name": "Location Name",
  "rooms.locations-table.actions": "Actions",

  /* 07.Team Page */
  "team.breadcrumbs.header": "{companyName} Team",
  "team.error.fetch": weWerentAbleTo("find your team members"),
  "team.error.update": weWerentAbleTo("update your team"),
  "team.success.update": "Your team has been updated.",
  "team.success.add_admin": "{email} has been added to your team as an admin.",
  "team.success.remove_admin":
    "{email} has been successfully removed from admin",
  "team.success.add_user": "{email} has been added to your team.",
  "team.success.remove_user": "Removed {email} successfully",
  "team.success.restore_user": "Restored {email} successfully",
  "team.success.deactivate_user": "Deactivated {email} successfully",
  "team.success.reactivate_user": "Reactivated {email} successfully",
  "team.warning.already_member": " is already a member of the team.",
  "team.add_admin.modal_title": `${addAdmin} to {companyName}`,
  "team.add_admin.modal_body":
    "Enter an email address below to grant admin privileges for your team. They will receive an email to access the Nest.",
  "team.add_user.modal_title": `${addUser} to {companyName}`,
  "team.user_request.modal_title": `New User Account Request`,
  "team.user_exists.modal_title": `This user has been invited`,
  "team.view-team": "View Team",
  "team.user_request.modal_body": `A new user account has been requested with the email {newUserEmailFromNotification}. Do you want to invite them to the Nest?`,
  "team.user_exists.modal_body": `{email} has already been invited to the Nest. To manage your team members, go to the Team page.`,
  "team.user_request.modal_invite": `Invite`,
  "team.add_user.modal_body":
    "Enter an email address below to automatically add a team member. They will receive an email to access the Nest.",
  "team.add_user.placeholder": "user",
  "team.add_admin.placeholder": "admin",
  "team.message.showing": "Showing {count} team members",
  "team.buttons.action": "Actions",
  "team.buttons.add_new": "Add New",
  "team.buttons.add_user": "Add New User",
  "team.buttons.add_admin": "Add Admin",
  "team.buttons.admin": "Administrator",
  "team.buttons.user": "Member",
  "team.buttons.set_admin": "Make Administrator",
  "team.buttons.remove_admin": "Remove Administrator Status",
  "team.buttons.deactivate": "Deactivate Account",
  "team.buttons.reactivate": "Reactivate Account",
  "team.buttons.set_admins": "Make Administrators",
  "team.buttons.remove_admins": "Remove Administrator Statuses",
  "team.buttons.remove-from-organization": "Remove From Organization",
  "team.buttons.remove-account": "Remove Account",
  "team.buttons.remove-accounts": "Remove Accounts",
  "team.buttons.restore-account": "Restore Account",
  "team.buttons.restore-accounts": "Restore Accounts",
  "team.buttons.add.new": "Add New",
  "team.buttons.not-available": "No actions available.",
  "team.buttons.no_action":
    "No actions available for the selected team members because of their different roles and statuses. Please select team members with the same roles and statuses.",
  "team.users.table.name": "Users Table",
  "team.table.header.email": "Email Address",
  "team.table.header.name": "Name",
  "team.table.header.role": "Role",
  "team.table.header.status": "Account Status",
  "team.table.header.mfa-setting": "MFA Setting",
  "team.table.role.admin": "Administrator",
  "team.table.role.member": "Member",
  "team.table.status.active": "Active",
  "team.table.status.removed": "Removed",
  "team.table.status.never-logged-in": "Never Logged In",
  "team.search.placeholder": "Search Team",
  "team.add.duplicate.administrator": "{value} is already an administrator",
  "team.add.duplicate.member": "{value} is already a member of the team.",
  "team.add.invalid.email.format": "{value} is invalid format.",
  "team.show.removed.users": "Show Removed Users",
  "team.hide.removed.users": "Hide Removed Users",
  "team.remove-single-user-modal-body":
    "Are you sure you want to remove {value}? They will no longer be able to access this Nest organization.",
  "team.remove-user-modal-body": "Are you sure you want to remove: ",
  "team.remove-user-modal-body-part-two":
    "They will no longer be able to access this Nest organization.",
  "team.remove-user-modal-title": "Are you sure?",
  "team.remove-yes-remove-user": "Yes, Remove User",
  "team.remove-yes-remove-users": "Yes, Remove Users",
  "team.table.mfa-setting-on": "On",
  "team.table.mfa-setting-off": "Off",
  /* 08. Transfer Device page */
  "transfer.header": "TRANSFER REQUEST",
  "transfer.body": "A transfer has been requested for a device you own.",
  "transfer.instructions.body":
    "Someone at {company_name} has requested a transfer of ownership for the following Owl device. If you confirm the transfer, the device will be moved to {company_name}'s account.",
  "transfer.instructions.serial": "Serial number",
  "transfer.buttons.confirm": "Transfer",
  "transfer.buttons.deny": "Deny",
  "transfer.error.fetch": weWerentAbleTo("find this transfer request"),
  "transfer.error.respond": weWerentAbleTo("record your response"),
  "transfer.done.thanks": "Thank you",
  "transfer.done.confirmed":
    "Your device has been transferred to {company_name}.",
  "transfer.done.denied": "We've closed this transfer request.",
  "transfer.confirmation-modal.title": "Confirm Transfer",
  "transfer.confirmation-modal.body":
    "Are you sure you want to transfer ownership of this device? You will no longer have access to this device and will not be able to use it.",
  "transfer.confirmation-modal.save-button-text": "Transfer",

  /* 09. Activate device page. */
  "activate-device.form.title": "Welcome!",
  "activate-device.form.subtitle": "Meeting HQ Registration",
  "activate-device.form.enter-code": "New Device Activation Code",
  "activate-device.form.enter-code-placeholder": "Activation Code",
  "activate-device.form.submit": "Activate",
  "activate-device.form.error": "Please enter a valid activation code",
  "activate-device.login.title": "Activate your Meeting HQ",
  "activate-device.ensure.MOP.registered":
    "Please ensure you have registered your Meeting Owl before registering your Meeting HQ.",
  // "activate-device.toast.success": "Device set up initialized.",  // this language is for when we put the modal back
  "activate-device.toast.success": "Device set up completed.",
  "activate-device.toast.already_registered": "Device is already registered.",
  "activate-device.toast.unknown":
    "Could not find a device with this activation code.",
  "activate-device.wizard.device-setup": "Device Set Up",
  "activate-device.wizard.device-setup.site.title":
    "Add the location of your new device",
  "activate-device.wizard.device-setup.site.description":
    "In order to keep your flock of Owls organized in the Nest portal, first please provide the location of your new device.",
  "activate-device.wizard.device-setup.site.prompt":
    "Please enter or search for office location",
  "activate-device.wizard.device-setup.site.placeholder":
    "Search for location or enter a new one",
  "activate-device.wizard.device-setup.room.title":
    "Add the room name for your new device",
  "activate-device.wizard.device-setup.room.description":
    "Also, please provide the location of your new device.",
  "activate-device.wizard.device-setup.room.prompt":
    "Please enter or search for the room name at this location",
  "activate-device.wizard.device-setup.room.placeholder":
    "Search for location or enter a new one",
  "activate-device.wizard.device-setup.setup-later": "Set up later",
  "activate-device.wizard.device-setup.submit": "Submit",
  "activate-device.wizard.setup-completed": "Setup Completed!",
  "activate-device.wizard.setup-completed.description":
    "Thank you for completing Nest setup! Please return to device to integrate Zoom Room.",
  "activate-device.wizard.setup-completed.button": "Close",
  "activate-device.wizard.skip-setup": "You ready to go?",
  "activate-device.wizard.skip-setup.description":
    "You will need to complete the set up in Nest Portal to fully use the features of your device.",
  "activate-device.wizard.skip-setup.close-button": "Set Up Later",
  "activate-device.wizard.skip-setup.set-up-now": "Set Up Now",
  "activate-device.wizard.device-name": "Activate your Meeting HQ",
  "activate-device.wizard.device-name.button.next": "Next",
  "activate-device.wizard.device-name.input.description":
    "Please give your new Meeting HQ a name",
  "activate-device.wizard.device-name.input.label": "Meeting HQ Name",
  "activate-device.wizard.device-name.register.error":
    "There was an error registering the Meeting HQ.",
  "activate-device.wizard.payment-method": "Activate Your Meeting HQ",
  "activate-device.wizard.payment-method.button.other-payer":
    "Send to Other Payer",
  "activate-device.wizard.payment-method.button.start-subscription":
    "Complete Activation",
  "activate-device.wizard.payment-method.button.already-paid": "Continue",
  "activate-device.wizard.payment-method.has-payment-method.description":
    "Welcome to the Meeting HQ. To activate your device, please confirm your subscription.",
  "activate-device.wizard.payment-method.has-payment-method.proceed-confirmation":
    "If you proceed with activation, we will bill the following card on file immediately and your subscription will begin:",
  "activate-device.wizard.payment-method.no-payment-method.description":
    "Welcome to the Meeting HQ! The Meeting HQ requires a subscription to operate our proprietary Quick Start software. The software license allows users to run video conferencing meetings on multiple platforms.",
  "activate-device.wizard.payment-method.no-payment-method.description2":
    "For most purchases, the first year subscription payment is included in your initial purchase price. After that first year, your subscription will automatically renew at $20 per month, paid annually, using the billing information entered below.",
  "activate-device.wizard.payment-method.no-payment-method.subscription.starts":
    "Your subscription will begin immediately.",
  "activate-device.wizard.payment-method.no-payment-method.other-payer":
    "Not the one with the credit card?",
  "activate-device.wizard.payment-method.error.fetching-payment-method":
    "Error fetching payment method",
  "activate-device.modal.payment-method": "Add Payment Method",
  "activate-device.modal.activate-message-part-one":
    " Welcome to the Meeting HQ! The Meeting HQ requires a subscription to operate our proprietary Quick Start software. The software license allows users to run video conferencing meetings on multiple platforms.",
  "activate-device.modal.activate-message-part-two":
    " For most purchases, the first year subscription payment is included in your initial purchase price. After that first year, your subscription will automatically renew at $20 per month, paid annually. Please provide billing information for the annual renewals.",
  "activate-device.modal.activate-message-need-to-update-card":
    "Your current payment method needs to be updated.",
  "activate-device.modal.activate-continue-to-nest": "Continue To The Nest",
  "activate-device-signed-in-as": "Signed in as:",
  "activate-device.modal.payment-setup-already":
    "Thank you. Your method of payment is already set up!",
  "activate-device.modal.billing-address-required":
    "A billing address is required to finalize your Meeting HQ subscription.",
  "activate-device.modal.errors.saving-address-to-account":
    "Error saving Billing Address to your account. Please try again",
  "activate-device.modal.success.saving-address-to-account":
    "Billing address succcessfully added.",
  // Subscription Table
  "activate-device.wizard.payment-method.subscription-table.label.subscription":
    "Subscription",
  "activate-device.wizard.payment-method.subscription-table.text.subscription":
    "Meeting HQ Subscription",
  "activate-device.wizard.payment-method.subscription-table.label.device":
    "Device(s)",
  "activate-device.wizard.payment-method.subscription-table.text.device":
    "Meeting HQ(s)",
  "activate-device.wizard.payment-method.subscription-table.label.quantity":
    "Quantity",
  "activate-device.wizard.payment-method.subscription-table.label.period":
    "Period",
  "activate-device.wizard.payment-method.subscription-table.text.period":
    "12 Months",
  "activate-device.wizard.payment-method.subscription-table.label.cost": "Cost",

  "activate-device.wizard.other-payer": "Activate Your Meeting HQ",
  "activate-device.wizard.other-payer.description":
    "Invite a billing manager to complete payment information.",
  "activate-device.wizard.other-payer.label": "Billing Manager Email",
  "activate-device.wizard.other-payer.time":
    "Once you invite a billing manager to sign up for the Meeting HQ subscription, they have 7 days to enter billing information. In the meantime, your Meeting HQ will be activated during this grace period.",
  "activate-device.wizard.other-payer.button.submit": "Send Email",
  "activate-device.wizard.other-payer.button.back": "Back to Payment Method",
  "activate-device.wizard.other-payer.toast.success":
    "Email sent to billing manager.",
  "activate-device.wizard.other-payer.toast.no-email":
    "Must provide an email address.",
  "activate-device.wizard.other-payer.toast.invalid-email":
    "Typo in email format.",
  "activate-device.wizard.other-payer.toast.error":
    "There was an error sharing the link.",
  "activate-device.wizard.billing-address": "Enter Billing Address",
  "activate-device.wizard.new-payment-method":
    "Enter Subscription Payment Method",
  "activate-device.wizard.mhq-is-activated":
    "Meeting HQ Subscription Activated",
  "activate-device.wizard.mhq-is-activated-description":
    "Your Meeting HQ Subscription is now activated. Please finish the setup process on the Meeting HQ.",
  "activate-device.wizard.saving": "Saving",
  "activate-device.payment.method": "Payment Method",

  /* 10. Analytics  */
  "analytics.error.fetch": "Error loading analytics.",
  "analytics.loading": "Loading...",
  "analytics.noData": "No usage data found for selected Owls",
  "analytics.usageInformation.title":
    "Owl usage information grouped by {value}",
  "analytics.usageInformation.description": " ",
  "analytics.usageChart.averagePeopleInMeeting": "Average People in Meeting",
  "analytics.usageChart.totalMeetings": "Total Meetings",
  "analytics.optedOut.noData": "No usage data is available for your Owl(s).",
  "analytics.optedOut.noData.changeSetting":
    "To change the setting, please contact your Owl(s) administrator.",

  // Billing
  "billing.heading": "Billing",
  "billing.payment.failed":
    "It seems your past attempts to pay for your subscription failed. Please try again by entering your billing info.",
  "subscriptions.heading": "Subscriptions",
  "subscriptions.remove-payment-method": "Remove Payment Method",
  "subscriptions.remove-payment-method-confirmation":
    "Are you sure you want to remove your payment method? You will be invoiced for any subscriptions.",
  "subscriptions.card-removal-successful":
    "We have successfully removed your card.",
  "subscriptions.card-removal-failed": "We were unable to delete your card.",
  "subscriptions.devices-with-subscriptions-table":
    "Device Subscriptions Table",

  // Alerts
  "alerts.heading": "Alerts",
  "alerts.email.heading": "Device Alert Notification Settings",
  "alerts.headers.notifications": "Notifications",
  "alerts.headers.unread": "Unread",
  "alerts.headers.read": "Read",
  "alerts.headers.mark_all_as_read": "Mark All As Read",
  "alerts.error.fetching-alerts": "Error fetching alerts",
  "alerts.error.updating-alerts": "Error Updating alerts",
  "alerts.modal.software-update-header": "New Software Update",
  "alerts.modal.software-update-body":
    "Software update {softwareVersion} is available for {deviceType}s. Release notes are available ",
  "alerts.modal.software-update-link": "here.",

  // Licenses
  "licenses.headers.device": "Device",
  "licenses.headers.device-type": "Device Type",
  "licenses.headers.hw-serial": "Serial Number",
  "licenses.headers.alias": "Name",
  "licenses.headers.status": "Status",
  "licenses.headers.active": "Active",
  "licenses.headers.grace": "Grace",
  "licenses.headers.created": "Created",
  "licenses.headers.start-date": "Start Date",
  "licenses.not-available": "N/A",
  "licenses.unassigned": "Unassigned",
  "licenses.is-active": "Active",
  "licenses.is-inactive": "Inactive",
  "licenses.is-grace": "Yes",
  "licenses.is-not-grace": "No",
  "licenses.no-licenses-found": "No licenses found.",
  "licenses.error.fetching-licenses": "Error fetching licenses",
  "licenses.error.creating-licenses": "Error purchasing licenses",
  "licenses.error.fetching-estimate": "Error fetching estimate",
  "licenses.error.fetching-invoices": "Error fetching invoices",
  "licenses.error.fetching-saas-checks": "Error fetching Saas Checks",

  //Invoices
  "invoices.pdf": "PDF",
  "invoices.number": "Invoice #",
  "invoices.date": "Date",
  "invoices.date-paid": "Date Paid",
  "invoices.tax": "Tax",
  "invoices.total": "Total",
  "invoices.description": "Description",
  "invoices.no-invoices-found": "No invoices found.",

  // Payment Method
  "payment-method.headers.type": "Type",
  "payment-method.headers.last_4": "Last 4 Digits",
  "payment-method.headers.expiration": "Expiration",
  "payment-method.headers.zip-code": "Zip Code",
  "payment-method.headers.added-by": "Added By",
  "payment-method.headers.created": "Created",
  "payment-method.not-available": "N/A",
  "payment-method.charge-button": "Charge",
  "payment-method.replace-button": "Replace",
  "payment-method.edit-button": "Edit",
  "payment-method.add-card-button": "Add Card",
  "payment-method.add-button": "Add",
  "payment-method.invoice-label": "Invoice",
  "payment-method.request-invoice": "Request Invoicing",
  "payment-method.no-payment-method-found": "No payment method found.",
  "payment-method.please-enter-info":
    "You have unpaid licenses. Please supply payment information by clicking the button below.",
  "payment-method.error.fetching-payment-method":
    "Error fetching payment method",
  "payment-method.error.needs-replaced": "Card on file needs replaced.",
  "payment-method.success.invoice":
    "Success! You will be contacted to pay via invoice.",
  "payment-method.error.invoice":
    "We were unable to invoice you. Please contact us.",

  // Upcoming Invoices
  "upcoming-invoices.card-title": "Payment Method",
  "upcoming-invoices.no-upcoming-invoices": "You have no upcoming invoices.",
  "upcoming-invoices.will-automatically-renew-on":
    "Will automatically renew on: ",
  "upcoming-invoices.will-expire-on": "Will expire on: ",
  "upcoming-invoices.expired-on": "Your subscription expired on: ",
  "upcoming-invoices.payment-method": "Payment method: ",
  "upcoming-invoices.payment-method.please-add": "Please add payment method.",
  "upcoming-invoices.payment-method.please-update":
    "Please update your payment method.",
  "upcoming-invoices.warning-icon": "Warning icon",
  "upcoming-invoices.expiry-date": "Expiry date ",

  // Past Invoices
  "past-invoices.card-title": "Past Invoices",
  "past-invoices.no-invoices": "You have no invoices on file.",
  "past-invoices.paid": "Paid: ",
  "past-invoices.total": "Total: ",
  "past-invoices.download": "Download",

  // Devices with Subscriptions
  "devices-with-subscriptions.cancel-subscription": "Cancel subscription",
  "devices-with-subscriptions.heading": "Devices With Subscriptions ",
  "devices-with-subscriptions.error-boundary":
    "We were unable to load subscriptions at this time. Please try again shortly or contact support if the issue persists.",
  "devices-with-subscriptions.table-row.error-boundary":
    "Error loading subscription. Please try again shortly or contact support if the issue persists.",
  "devices-with-subscriptions.search.error-boundary":
    "Please try again shortly or contact support if the issue persists.",

  // Stripe Modal
  "stripe-modal.replace.header": "Replace Payment Method",
  "stripe-modal.payment.header": "Make a payment:",
  "stripe-modal.add.header": "Add Payment Method",
  "stripe-modal.card-info": "Card Information",
  "stripe-modal.card.label": "Please enter your billing information:",
  "stripe-modal.charge.warning":
    "The following outstanding charges will be charged to the card:",
  "stripe-modal.buttons.cancel": "Cancel",
  "stripe-modal.buttons.save": "Save",
  "stripe-modal.buttons.charge": "Charge Card Now",
  "stripe-modal.success.saving-card-to-account":
    "Payment method succcessfully added.",
  "stripe-modal.errors.saving-card-to-account":
    "Error saving card to your account.",
  "stripe-modal.billing-email-label": "Billing Email Address",
  "stripe-modal.billing-email-error":
    "Please enter your billing email address.",
  "stripe-modal.billing-email-placeholder": "email@example.com",
  "stripe-modal.addressLine1-label": "Address Line 1",
  "stripe-modal.addressLine1-placeholder": "Street Address",
  "stripe-modal.addressLine1-error": "Please enter your Street Address",
  "stripe-modal.addressLine2-label": "Address Line 2",
  "stripe-modal.addressLine2-placeholder": "Apartment, unit, suite, etc.",
  "stripe-modal.city-label": "City",
  "stripe-modal.city-placeholder": "Enter your city",
  "stripe-modal.city-error": "Please enter your city",
  "stripe-modal.usState-label": "State",
  "stripe-modal.usState-placeholder": "eg: NY, MA",
  "stripe-modal.usState-error": "Please enter your state",
  "stripe-modal.zipCode-label": "Postal code",
  "stripe-modal.zipCode-placeholder": "eg: 33212",
  "stripe-modal.zipCode-error": "Please enter your 5 digit zip code",
  "admin.welcome": "Welcome to the Nest Admin",
  "accounts.error.switch-accounts":
    "Switching accounts failed. Please refresh the page and try again.",
  "accounts.error.fetch-accounts":
    "Fetching accounts failed. Please refresh the page.",
  "admin.promote.no-user": "User with that email not found.",
  "admin.promote.now-admin": " is now an admin",
  "account.title": "Account",
  "account.setting": "Account Settings",
  "account.switch-account": "Switch Account",
  "account.switch-account-description":
    "Select which account you'd like to go to:",
  "account.sign-out": "Sign Out",
  "account.support-actions": "Support Actions",
  "account.notification.setting": "Notification Settings",
  "account.notification.new-user-request": "New user account request",
  "account.notification.software-update":
    "Software update available for device types",
  "account.notification.software-update-reminder":
    "Schedule software update reminder",
  "account.notification.software-update-summary": "Software update summary",
  "account.notification.device-offline": "Device offline after",
  "account.notification.header": "Enable event specific notifications",
  "account.notification.in-app": "Nest",
  "account.notification.email": "Email",
  "account.settings.notification-preferences-warning":
    "There was an issue loading notification preferences",
  "account.settings.notification-preferences-time-minutes": "{value} minutes",
  "account.settings.notification-preferences-time-hours": "{value} hours",
  "account.settings.form-placeholder": "First and last name",
  "account.settings.tool-tip-in-app-notifications":
    "Nest notifications for new user account requests must be on.",
  "account.settings.tool-tip-in-app-software-notifications":
    "Nest notifications for software updates must be on.",
  "account.settings.update-name": "Update Name",
  "account.settings.add-your-name": "Add Your Name",
  "account.settings.add-your-email": "Add Your Email",
  "account.settings.change-password": "Change Password",
  "account.settings.modal-name": "Name",
  "account.settings.modal-error": "Please enter your name. No numeric values.",
  "account.change-email-modal.title": "Change Email Address",
  "account.change-email-modal.body-text-your-email-must-end-in":
    "Your email address must end in:",
  "account.change-email-modal.current-email": "Current Email Address",
  "account.change-email-modal.new-email": "New Email Address",
  "account.change-email-modal.enter-valid-email":
    "Please enter a valid email address",
  "account.change-email-modal.identical-email-entered":
    "Email address entered is identical to current email address.",
  "account.verify-phone-number-modal.title": "Verify Your Phone Number",
  "account.verify-phone-number-modal.body":
    "Please enter the code that was sent to:",
  "account.verify-phone-number-modal.error": "Incorrect code.",
  "account.verify-phone-number-modal.done": "Done",
  "account.change-email-modal.next": "Next",
  "account.change-password-modal.title": "Change Password",
  "account.change-password-modal.current-password": "Current Password",
  "account.change-password-modal.new-password": "New Password",
  "account.change-password-modal.password-changed-successfully":
    "Password Changed Successfully",
  "account.change-phone-number-modal.title": "Add your phone number",
  "account.change-phone-number-modal.phone-number": "Phone Number",
  "account.remove-phone-number-modal.title": "Remove Phone Number",
  "account.remove-phone-number-modal.body":
    "Are you sure you want to remove your phone number? If you have multifactor authentication turned on, removing your phone number will turn it off.",
  "account.remove-phone-number-modal.save-text": "Yes, Remove Phone Number",

  "account.email-confirmation-modal.title": "Change Email Address",
  "account.email-confirmation-modal.please-enter-code":
    "Please enter the code that was emailed to:",
  "account.email-confirmation-modal.code": "Code",
  "account.email-confirmation-modal.done": "Done",
  "account.mfa.title": "Multifactor Authentication",
  "account.mfa.body": "Manage your multifactor authentication settings",
  "account.mfa.switch-title": "Multifactor Authentication",
  "account.mfa.edit-button": "Edit Button",
  "account.mfa.delete-button": "Delete Button",
  "account.changes-saved": "Changes saved",
  "account.management": "Account Management",
  "account.delete.my-account": "Delete My Account",
  "account.delete.my-account-description":
    "Are you sure you want to delete your account? You will need to be invited to the Nest to rejoin your organization.",
  "account.delete.data": "Delete My Data",
  "account.delete.data-description":
    "Are you sure you want to delete your user data? Once you complete the form, we will review your request. ",
  "account.delete.nevermind": "Nevermind",
  "account.delete.learn-more": "Learn more",
  "account.delete.skip": "Skip",
  "account.delete.transfer": "Transfer",
  "account.delete.transfer-number-modal-body":
    "You have {value} devices registered to your email address. Would you like to transfer ownership of these devices? If you do not transfer ownership of these devices, they will need to be reregistered to be used.",
  "account.delete.transfer-modal-title": "Transfer Device Ownership",
  "account.delete.transfer-confirmed-title": "Devices Have Been Transferred",
  "account.delete.transfer-devices-team-members-body":
    "Select the user you would like to transfer device ownership to:",
  "account.delete.transfer-to-complete":
    "Your devices have transferred ownership to {value}",
  "account.delete-confirm-cancel": "Are you sure you want to cancel?",
  "account.delete-devices-transferred":
    "Your devices have transferred ownership.",
  "account.delete-transfer-error": "An error occurred. Try again.",
  "account.delete-transfer-success":
    "Your devices have successfully been transferred.",
  "account-delete-team-domain": "Other (must use same email domain)",
  "account-delete-unable-to-find-team":
    "Could not find team members. Please enter email.",
  //Owl Settings
  "settings.title": "Device Settings",
  "settings.edit": "Edit Settings",
  "settings.continue": "Yes, Continue",
  "settings.error.fetch": weWerentAbleTo("find your device settings"),
  "settings.success.update": "Your settings were successfully saved.",
  "settings.success.update.multi":
    "Your settings were successfully saved. Changes may take up to 30 seconds to take effect.",
  "settings.success.update.offline.devices":
    "Your settings were successfully saved and may take up to 30 seconds to take effect. For offline devices, the changes will be implemented once the devices are back online.",
  "settings.error.device-update": weWerentAbleTo("update the owl's settings"),
  "settings.multi.state.text":
    "Multiple settings currently applied to selected devices",
  "settings.single.offline":
    "This device is offline. The changes will be saved and implemented once it is back online.",
  "tooltip.multi.state":
    "Clicking the toggle will turn this setting on for all of the selected devices. You can then turn the setting off if preferred.",
  "tooltip.multi.state.logo":
    "Clicking the toggle will turn this setting on for all of the devices. You can then turn the setting off if preferred.",
  "tooltip.multi.state.passcode":
    "Clicking the toggle will turn on a passcode for all of the selected devices and a passcode will need to be set. Alternatively you can turn the setting off if preferred. ",
  "settings.dropdown.change": "Change Settings",
  "settings.dropdown.software": "Update Software",
  "settings.dropdown.reboot": "Reboot",
  "settings.passcode.not.sent": "No passcode has been set",
  "settings.set.passcode": "Set Passcode",
  "settings.set.passcode.description":
    "Set a 6+ digit passcode to lock Bluetooth connections to this device.",
  "settings.change.passcode": "Change Passcode",
  "settings.enter.new.passcode": "Enter New Passcode",
  "settings.confirm.passcode": "Confirm New Passcode",
  "settings.modal.title": "Device Settings",
  "settings.offline.modal.title": "Devices Are Offline",
  "settings.offline.modal.body": `{number} of the selected devices are offline. The changes will be saved and implemented once they are back online.`,
  "settings.continueMOP.modal.body":
    "Default settings can only be applied to Meeting Owl Pro, Meeting Owl 3, and Owl Bar devices. Would you like to continue with the selected Meeting Owl Pros, Meeting Owl 3’s, and Owl Bars?",
  "settings.continueMOP.modal.body.with.MO4P":
    "Default settings can only be applied to Meeting Owl Pro, Meeting Owl 3, Meeting Owl 4 Pro and Owl Bar devices. Would you like to continue with the selected Meeting Owl Pros, Meeting Owl 3’s, Meeting Owl 4 Pros and Owl Bars?",
  "settings.MOPonly.modal.body":
    "Default settings can only be applied to Meeting Owl Pro, Meeting Owl 3, and Owl Bar devices.",
  "settings.MOPonly.modal.body.with.MO4P":
    "Default settings can only be applied to Meeting Owl Pro, Meeting Owl 3, Meeting Owl 4 Pro and Owl Bar devices.",
  "settings.ews.modal.title": "Exit Without Saving",
  "settings.ews.modal.cancel-btn-text": "Exit without saving",
  "settings.ews.modal.body":
    "Are you sure you want to exit? Your changes won't be saved.",
  "settings.ews.modal.save": "Save and Exit",
  "settings.passcode.tooltip":
    "A Bluetooth Passcode has not been set. Either set a Bluetooth Passcode or turn it off.",
  "settings.noise.reduction.description":
    "Noise reduction removes unchanging sources of background noise (like HVAC systems).",
  "settings.double.talk.description":
    "Double talk allows remote participants to hear local participants when both are speaking at the same time. Increasing the value may increase echo in different room setups.",
  "settings.passcode.description":
    "Set a numeric passcode to restrict who can access this Owl's settings. If you have a passcode set currently, this will override that setting.",
  "settings.flip.device.title": "Vertically Flip Video",
  "settings.flip.camera.description": "Flip device’s video upside down",
  "settings.presenter.enhance.title": "Presenter Enhance",
  "settings.presenter.enhance.description": "Camera actively follows presenter",
  "settings.pano.title": "360° Pano",
  "settings.pano.description": "Show panoramic view",
  "settings.lock.and.zoom.title": "Lock and Zoom",
  "settings.lock.and.zoom.description":
    "Lock camera to show the entire image. To adjust the view, please use the Meeting Owl App.",
  "settings.auto-frame.everyone.title": "Auto Frame Everyone",
  "settings.auto-frame.everyone.description": "Crop video to show all people",
  "settings.selected.owls": "Devices selected:",
  "settings.tab-head.video-settings": "Video Settings",
  "settings.tab-head.advanced-audio-settings": "Audio Settings",
  "settings.tab-head.set-owl-passcode": "Set Bluetooth Passcode",
  "settings.noise-reduction": "Noise Reduction",
  "settings.double-talk": "Double Talk",
  "settings.owl-passcode": "Bluetooth Passcode",
  "settings.show": "SHOW",
  "settings.hide": "HIDE",
  "menu.search.devices": "Search Devices",
  "settings.conflicted-modal-title": "Conflicting Settings",
  "settings.conflicted-presenter-enhance-on-auto-frame-off":
    "Turning on Presenter Enhance will turn off Auto Frame for all selected Owl Bars.",
  "settings.conflicted-presenter-enhance-on-lock-and-zoom-off":
    "Turning on Presenter Enhance will turn off Lock and Zoom for all selected Owl Bars.",
  "settings.conflicted-presenter-enhance-on-lock-zoom-and-auto-frame-off":
    "Turning on Presenter Enhance will turn off Auto Frame Everyone and Lock and Zoom for all selected Owl Bars.",
  "settings.conflicted-lock-and-zoom-on-presenter-enhance-off":
    "Turning on Lock and Zoom will turn off Presenter Enhance for all selected Owl Bars.",
  "settings.conflicted-lock-and-zoom-on-auto-frame-off":
    "Turning on Lock and Zoom will turn off Auto Frame for all selected Owl Bars.",
  "settings.conflicted-lock-and-zoom-on-auto-frame-and-presenter-enhance-off":
    "Turning on Lock and Zoom will turn off Auto Frame Everyone and Presenter Enhance for all selected Owl Bars.",
  "settings.conflicted-auto-frame-on-presenter-enhance-off":
    "Turning on Auto Frame will turn off Presenter Enhance for all selected Owl Bars.",
  "settings.conflicted-auto-frame-on-lock-and-zoom-off":
    "Turning on Auto Frame will turn off Lock and Zoom for all selected Owl Bars.",
  "settings.conflicted-auto-frame-on-lock-zoom-and-presenter-enhance-off":
    "Turning on Auto Frame Everyone will turn off Lock and Zoom and Presenter Enhance for all selected Owl Bars.",
  "settings.conflicted-turn-on-auto-frame": "Turn on Auto Frame",
  "settings.conflicted-turn-on-presenter-enhance": "Turn on Presenter Enhance",
  "settings.conflicted-turn-on-lock-and-zoom": "Turn on Lock and Zoom",
  "settings.owl.bar": "OWL BAR",
  "settings.meeting.owl": "MEETING OWL",

  // Settings Page
  "settings.data-sharing-card.head": "Data Sharing",
  "settings.data-sharing-card.overview":
    "We use device usage and meeting analytics, none of which have any user data or personal data, to help improve your experience with Owl devices.\nData sharing settings will be applied to all Owls in your fleet, including Owls that are newly registered to your organization in the future. ",
  "settings.data-sharing-card.enable.button": "Enable Data Share On All",
  "settings.data-sharing-card.disable.button": "Disable Data Share On All",
  "settings.data-sharing-card.enabled-for": "Data sharing enabled for",
  "settings.data-sharing-card.disabled-for": "Data sharing disabled for",
  "settings.data-sharing-card.meeting-analytics":
    "Your devices are recording meeting analytics.",
  "settings.data-sharing-card.of": "of",
  "settings.data-sharing-card.all": "all",
  "settings.data-sharing-card.devices": "devices.",
  "settings.data-sharing-card.note":
    "If data sharing has been deactivated locally on an Owl during registration, data sharing will remain off for that device regardless of the Nest setting. Deactivating data sharing will remove aggregate and individual device analytics from the Nest.",
  "settings.data-sharing-card.extra":
    "Deactivating data sharing will remove aggregate and individual device analytics from the Nest.",
  "settings.owl-logo.success":
    "Success! This change may take up to 5 minutes to show.",
  "settings.owl-logo.error":
    "An error occurred. Please try again in a few minutes.",
  "settings.owl-logo.tooltip": "Your devices are set to different states.",
  "settings.owl-logo.title": "Owl Labs Branding",
  "settings.owl-logo.description":
    "Show the Owl Labs logo on the screen while using any of the devices in your fleet.",
  "settings.unavailable": "This setting is currently unavailable.",
  "settings.owl-logo.branding": "Owl Labs branding",
  "settings.set.zoom.credentials": "Set Zoom Credentials",

  // Session Banner
  "session-banner.message":
    "The Nest is currently undergoing maintenance and you may see limited functionality at this time. Please visit our {supportLink} for more information.",
  "session-banner.link-title": "Support Site",

  //Support Actions
  "support-actions.support-actions": "Support Actions",
  "support-actions.change-name": "Change Name",
  "support-actions.update-name": "Update Name",
  "support-actions.transfers": "Transfers",
  "support-actions.last-login-requests": "Last Login Requests",
  "support-actions.last-login-successes": "Last Login Successes",

  //Alias Modal
  "alias.modal.change-name-of": "Change Name of ",
  "alias.modal.cancel": "Cancel",
  "alias.modal.save": "Save",

  //Bulk update software modal
  "bulk-update-software-modal.menu-item": "Update Software",
  "bulk-update-software-modal.some-devices-are-offline":
    "Device(s) Are Offline",
  "bulk-update-software-modal.offline-devices-body-text":
    "of the selected devices are offline. Only devices that are currently online will be updated.",
  "bulk-update-software-modal.all-selected-devices-offline-body-text":
    "All of the selected devices are offline. Only devices that are currently online can be updated.",
  "bulk-update-software-modal.back-button": "Back",
  "bulk-update-software-modal.device-offline-cannot-update":
    " - This device is offline and cannot be updated.",
  "bulk-update-software-modal.device-up-to-date":
    " - Software is up-to-date and cannot be updated.",
  "bulk-update-software-modal.toast-success":
    "Software update in progress. Device(s) will be updated within 15 minutes.",
  "bulk-update-software-modal.toast-fail": " devices(s) failed to update.",
  "bulk-update-software-modal.devices-cant-update":
    "Device(s) Cannot Be Updated",

  //Bulk reboot devices
  "bulk-reboot-devices.reboot-devices": "Reboot Devices",
  "bulk-reboot-devices.you-are-about-to-reboot-the-following-devices":
    "You are about to reboot the following devices:",
  "bulk-reboot-devices.some-devices-cannot-be-rebooted":
    "Some devices are not available to reboot. You are about to reboot the following devices:",
  "bulk-reboot-devices.selected-devices-cant-be-rebooted":
    "None of the devices that you selected can be rebooted at this time.",
  "bulk-reboot.reboot.success":
    "Device reboot has successfully started for {count} device(s). Your device(s) will be back online in a couple of minutes.",
  "bulk-reboot.reboot.failure":
    "Device reboot failed for {count} device(s). Try again in a few minutes.",

  // Bulk Toggle Favorites
  "bulk-toggle-favorite.title-favorite": "Add to favorites",
  "bulk-toggle-favorite.title-unfavorite": "Remove from favorites",
  "bulk-toggle-favorite.body-favorite":
    "You are about to add the following devices to your favorites:",
  "bulk-toggle-favorite.body-unfavorite":
    "You are about to remove the following devices from your favorites:",
  "bulk-toggle-favorite.add-to-favorites-failed":
    "Failed to add device(s) to your favorites.",
  "bulk-toggle-favorite.remove-from-favorites-failed":
    "Failed to remove device(s) from your favorites.",
  "bulk-toggle-favorite.add-to-favorites": "Add To Favorites",
  "bulk-toggle-favorite.remove-from-favorites": "Remove From Favorites",

  // '/admin' route
  "admin.promote-to-administrator": "Promote to Administrator",
  "admin.checks-earlier": "See checks from a day earlier",
  "admin.return-checks-later": "Return to the checks a day later",
  "admin.export": "Export",
  "admin.purchase": "Purchase",
  "admin.delete.license": "Delete License and License Claim",
  "admin.customer-process":
    "This is part of the process when a customer returns a device. Subscription modifications will need to be made manually to give a refund.",
  "admin.replace-device": "Replace the device for this license",
  "admin.identify-device": "Please identify the new device:",
  "admin.replace": "Replace",
  "admin.company-account": "Company Account:",
  "admin.find-device": "Find Device:",
  "admin.search": "Search",
  "admin.billing-email": "Billing Email:",
  "admin.stripe-subscription-id": "Stripe Subscription Id",
  "admin.stripe-customer-id": "Stripe Customer Id",
  "admin.purchase-licenses": "Purchase Licenses",
  "admin.delete": "Delete",
  "admin.email-to-upgrade": "Email of another user to upgrade to admin:",

  // New Fleet Settings
  "menu.fleet-settings": "Fleet Settings",
  "fleet-settings.software-updates":
    "Owls will receive automatic updates if they are plugged in and connected to Wi-Fi. ",
  "fleet-settings.software-updates-title": "Software Updates",
  "fleet-settings.automatic-updates": "Automatic Updates",
  "fleet-settings.learn-more": "Learn More",
  "fleet-settings.data.sharing": "Data share on all devices",
  "fleet-settings.mhq-setup.title": "MHQ Zoom Credentials",
  "fleet-settings.mhq-setup.description": "Enter the MHQ Zoom Credentials",
  "fleet-settings.mhq-setup.client-secret": "Client Secret",
  "fleet-settings.mhq-setup.client-id": "Client Id",
  "fleet-settings.enable-automatic-updates":
    "You have enabled automatic software updates",
  "fleet-settings.disable-automatic-updates":
    "You have disabled automatic software updates",
  "fleet-settings.error-automatic-updates":
    "There was a problem toggling automatic updates",
  "fleet-settings.error-loading-automatic-updates":
    "Error loading Automatic Updates.",
  "fleet-settings.mhq-saved": "Zoom integration saved",
  "fleet-settings.mhq-problem-saving":
    "There was a problem saving your zoom credentials",
  "fleet-settings.mhq-zoom-client": "Enter Zoom app Client Secret",
  "fleet-settings.mhq-zoom-client-id": "Enter Zoom app Client ID",
  "fleet-settings.calendar-integrate": "To integrate your calendar please",
  "fleet-settings.calendar-login": "login with google",
  "fleet-settings.calendar-return": "first, and then return to this page.",
  "fleet-settings.calendar-all-rooms":
    " If you already see all the rooms you have on your calendar you do not need to do anything else.",
  "fleet-settings.calendar-synced-from": "Synced From",
  "fleet-settings.calendar-synced-last": "Last Sync",
  "fleet-settings.device-management-title": "Device Management",
  "fleet-settings.mhq-setup-title": "MHQ Setup",
  "fleet-settings.data-sharing-title": "Data Sharing",

  // IoT Error
  "iot.statuses-not-received":
    "We're having trouble connecting to IoT servers. Some devices may appear offline until we're able to re-establish a connection.",

  // Favorites
  "favorite-devices.add": "Add to Favorites",
  "favorite-devices.remove": "Remove from Favorites",

  // Support Actions
  "support-actions-requested": "requested",
  "support-actions-un-registered": "Un-registered ",
  "support-actions-no-one": "no-one",
  "support-actions-from": "from",
  "support-actions-email": "Email#",
  "support-actions-resolved": "Resolved:",
  "support-actions-no-response": "No response",
  "support-actions-none": "None",

  // Last Logins
  "last-logins-login": "Login",
  "last-logins-from": "from",

  // Owl Connect
  "owl-connect.paired-devices": "Paired Devices",
  "owl-connect.primary-device": "Primary Device",
  "owl-connect.secondary-devices": "Secondary Devices",
  "owl-connect.drawer.error-boundary":
    "There was an error loading pairing data for this device. Please try again later. If the problem persists, please contact support.",
  "owl-connect.devices.error-boundary.title": "{label} - Error",
  "owl-connect.devices.error-boundary.description":
    "There was an error getting the {label}. Please try again soon. If the problem persists, please contact support.",

  // Software Update Scheduler
  "software-update-scheduler.schedule": "Schedule",
  "software-update-scheduler.devices": "Devices",
  "software-update-scheduler.software.update": "Software Update",
  "software-update-scheduler.cancel.update": "Cancel Update",
  "software-update-scheduler.schedule.update": "Schedule Software Update",
  "software-update-scheduler.schedule.schedule-update-failed":
    "Schedule Update Failed",
  "software-update-scheduler.schedule.schedule-update-reminder":
    "Schedule Update Reminder",
  "software-update-scheduler.schedule.schedule-update-successful":
    "Schedule Update Successful",
  "software-update-scheduler.update.now": "Update Software Now",
  "software-update-scheduler.update.description":
    "Select the date and time you would like these devices to update. Software updates could take up to 15 minutes to complete. Devices will update to the latest available software version.",
  "software-update-scheduler-next-version":
    "No new update available. The latest available update will be applied at the scheduled date and time, if a new update is available.",
  "software-update-scheduler.time.picker.description":
    "Devices will update at the selected time in their local time zone.",
  "software-update-scheduler.select.time": "Select Time To Update",
  "software-update-scheduler.select.date": "Select Date",
  "software-update-scheduler.name.update": "Name this update (optional)",
  "software-update-scheduler.error.401":
    "There was an error creating the scheduled software update: {value}. Please try again shortly or contact support if the problem persists.",
  "software-update-scheduler.error.create":
    "There was an error creating the scheduled software update. Please try again shortly or contact support if the problem persists.",
  "software-update-scheduler.error.update":
    "There was an error updating the scheduled software update. Please try again shortly or contact support if the problem persists.",
  "software-update-scheduler.error.add-no-update":
    "There was an error adding the devices to the scheduled software update: No update found. Please try again shortly or contact support if the problem persists.",
  "software-update-scheduler.error.add":
    "There was an error adding the devices to the scheduled software update. Please try again shortly or contact support if the problem persists.",
  "software-update-scheduler.error.remove":
    "There was an error removing the devices from the scheduled software update. Please try again shortly or contact support if the problem persists.",
  "software-update-scheduler.error.cancel":
    "There was an error canceling the scheduled software update. Please try again shortly or contact support if the problem persists.",
  "software-update-scheduler.update.now.modal.title": "Update Now",
  "software-update-scheduler.devices.already.scheduled":
    "One or more of the selected devices are in a scheduled software update. Would you like to override the scheduled software update to update these devices now?",
  "software-update-scheduler.device.already.scheduled":
    "{value} has a scheduled software update. Would you like to override the scheduled software update to update this device now?",
  "software-update-scheduler.update.now.button": "Update Now",
  "software-update-scheduler.update.non.scheduled.button":
    "Update Non-Scheduled Devices",
  "software-update-scheduler.review-scheduled-update": "View scheduled update",
  "software-update-scheduler.review-next-scheduled-update":
    "View next scheduled update",
  "scheduled-software-updates-modal.title": "Scheduled Software Updates",
  "scheduled-software-updates-modal.devices-on":
    "{numberOfDevices} device{plurality} on {datetime}",
  "scheduled-software-updates-modal.body":
    "Click a scheduled software update below to view more information.",
  "software-update-scheduler.error.create.update.now":
    "There was an error updating your device(s). Please try again shortly or contact support if the problem persists.",
  "software-update-scheduler.update.now.schedule.later":
    "Would you like to update the software for {value} now or schedule an update for later?",
  "software-update-scheduler.schedule.later": "Schedule Update for Later",
  "software-update-scheduler.status.not-in-this-update": "Not In This Update",
  "software-update-scheduler.status.not-started-yet": "Not Started Yet",
  "software-update-scheduler.status.successful": "Complete",
  "software-update-scheduler.status.updating": "Updating",
  "software-update-scheduler.table-headers.name": "Name",
  "software-update-scheduler.table-headers.device-type": "Device Type",
  "software-update-scheduler.table-headers.serial-number": "Serial Number",
  "software-update-scheduler.table-headers.sw-version": "SW Version",
  "software-update-scheduler.table-headers.update-status": "Update Status",
  "software-update-scheduler.schedule.failed.devices":
    "Schedule Update for Failed Devices",
  "software-update-scheduler.schedule.failed.devices.now":
    "Update Failed Devices Now",
  "software-update-scheduler.past.software.update":
    "Past Software Update: {value} device(s) on {date}",
  "software-update-scheduler.error.fetch":
    "There was an error fetching software updates. Please try again.",
  "software-update-scheduler.confirm.cancel.update.title": "Cancel Update?",
  "software-update-scheduler.confirm.cancel":
    "Are you sure you want to cancel this scheduled software update?",
  "software-update-scheduler.confirm.cancel.while.updating":
    "Are you sure you want to cancel this scheduled software update? Devices which are currently updating will continue to update. This will cancel for all devices that have not yet started to update.",
  "software-update-scheduler.beta": "-Beta",
  "software-update-scheduler-timezone-description":
    "This update is scheduled to take place at {time} local time. In your time zone {value}, you will see the update rolling out according to the following schedule:",
  "software-update-scheduler.confirm.scheduled.devices":
    "The selected devices are scheduled to update on: {date} at {time} per the device’s local time.",
  "software-update-scheduler.confirm.scheduled.device":
    "This device is scheduled to update on: {date} at {time} per the device’s local time.",
  "software-update-scheduler.device-search.placeholder": "Search all devices",
  "software-update-scheduler.device-count": "Device count:",
  "software-update-scheduler.add.paired.device":
    "One or more of the selected devices are currently paired to another Owl device. Paired devices must update to the same software version to maintain compatibility. Would you like to add the paired devices to this scheduled update?",
  "software-update-scheduler.yes.add.paired.devices": "Yes, add paired devices",
  "software-update-scheduler.time-zone-statement":
    "{count} devices in the {zone} will be updated at {time} your time",
  "software-update-scheduler.time-zone-statement-single-device":
    "{count} device in the {zone} will be updated at {time} your time",
  "software-update-scheduler.time-zone-need-to-update-at":
    " This update needs be scheduled at {time} on {date} or later to include all the devices in the update",

  // Needs Attention
  "needs-attention.title": "Needs Attention",
  "needs-attention.device-failed-to-update.title":
    "{numberOfFailedDevices} device{plurality} failed to update.",
  "needs-attention.device-failed-to-update.banner-text":
    "View device{plurality}",
  "needs-attention.settings.description":
    "Reshow closed alerts in the Needs Attention section of the Dashboard.",
  "needs-attention.settings.reshow.button-text": "Reshow Closed Alerts",
  "needs-attention.settings.reshow.success":
    "Closed alerts now displaying on Dashboard.",
  "needs-attention.settings.reshow.error":
    "Error reshowing closed alerts. Please try again in a bit or contact support if the problem persists.",
};
