import appLocaleData from "react-intl/locale-data/es";
import esMessages from "../locales/es_ES";

const EsLang = {
  messages: {
    ...esMessages,
  },
  locale: "es-ES",
  data: appLocaleData,
};
export default EsLang;
