import { SearchContextProvider } from "./context";
import {
  DeviceSearchFooter,
  DevicesSearchHeader,
  DevicesSearchTable,
} from "./components";

export const DevicesSearchFeature = () => {
  return (
    <>
      <SearchContextProvider>
        <DevicesSearchHeader />
        <DevicesSearchTable />
        <DeviceSearchFooter />
      </SearchContextProvider>
    </>
  );
};
