import { create } from "zustand";
import { productsResources } from "../../resources";
export const useLoadedProducts = create((set, state) => ({
  productsData: null,
  alreadyFetched: false,
  fetchData: async () => {
    if (state?.alreadyFetched) {
      return;
    }
    set({ alreadyFetched: true });

    try {
      const loadedProducts = await productsResources.read();
      const allProductsOption = { name: "All Products", isAllProducts: true };
      const productsData = [allProductsOption, ...loadedProducts];
      set({ productsData: productsData });
    } catch (error) {
      console.error("Error loading channels", error);
    }
  },
}));

useLoadedProducts.getState().fetchData();
