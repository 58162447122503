import {
  OwlInput,
  OwlInputInFieldButton,
  OwlInputTextField,
} from "@owl-labs/ui-components";
import { useEffect, useState } from "react";
import { useSearch } from "../../../context";
import { useDebounce } from "../../../../../hooks/debounce";
import { useQueryParams } from "../../../hooks/useQueryParams";

const SearchBar = () => {
  const { searchTerm, setSearchTerm } = useSearch();
  const [localSearchTerm, setLocalSearchTerm] = useState(() => {
    return searchTerm || "";
  });
  const { setQueryParam } = useQueryParams();
  const clearSearchTerm = (event) => {
    if (event) {
      event.preventDefault();
    }
    setLocalSearchTerm("");
  };

  const clearAllSearchTerms = () => {
    setLocalSearchTerm("");
    setSearchTerm("");
    setQueryParam("searchTerm", "");
  };

  const debouncedSetSearchTerm = useDebounce((value) => {
    setSearchTerm(value);
    setQueryParam("searchTerm", value);
  }, 1000);

  const shouldPreventContextSearch = (searchInputValue) => {
    return searchInputValue.length > 0 && searchInputValue.length <= 2;
  };

  const onChange = (event) => {
    const searchInputValue = event.target.value;
    setLocalSearchTerm(searchInputValue);
    if (searchInputValue === "") {
      clearSearchTerm();
    }

    if (shouldPreventContextSearch(searchInputValue)) {
      return;
    }
    debouncedSetSearchTerm(searchInputValue);
  };

  useEffect(() => {
    setLocalSearchTerm(searchTerm);
    if (searchTerm === "") {
      clearAllSearchTerms();
    }
    // eslint-disable-next-line
  }, [searchTerm]);

  return (
    <OwlInput className="w-full pl-5 sm:w-[20rem] ">
      <OwlInputTextField
        owlInputType="search"
        containerClassName="test"
        id="device-search"
        value={localSearchTerm}
        data-testid="search-input"
        placeholder="Example: MF1N"
        onChange={onChange}
        className="!h-[2.5rem] w-full border-2 sm:w-[20rem]"
      >
        <OwlInputInFieldButton clearFunction={clearAllSearchTerms} />
      </OwlInputTextField>
    </OwlInput>
  );
};
export default SearchBar;
