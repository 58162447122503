import { Input } from "reactstrap";
import { useSearch } from "../../../context";

import { defaultDevicesSearchProperties } from "../../../data";
import { useState } from "react";

const mappedDisplayNameSearchProperties = defaultDevicesSearchProperties.map(
  (property) => {
    return property.propertyDisplayName;
  }
);

const TableHeader = () => {
  const { isLoading, devices, selectedDevices, setSelectedDevices } =
    useSearch();
  const [selectedAll, setSelectedAll] = useState(false);
  const mappedHeaderProperties = mappedDisplayNameSearchProperties.map(
    (property, index) => (
      <th
        style={{ fontFamily: "Roobert-regular" }}
        className={"h-[3.125rem] text-nowrap pl-3 text-left"}
        key={index}
      >
        {property}
      </th>
    )
  );

  const handleSelectAll = () => {
    if (selectedDevices.length === devices.length) {
      setSelectedAll(false);
      return setSelectedDevices([]);
    }
    const allDeviceIds = devices.map((device) => device.uuid);
    setSelectedDevices(allDeviceIds);
    setSelectedAll(true);
  };
  if (isLoading) {
    return "";
  }

  return (
    <>
      <thead className="sticky top-0 translate-x-[-1px] bg-white">
        <tr>
          <th className="pl-2">
            <Input
              id="selectAll"
              name="selectAll"
              type="checkbox"
              onChange={handleSelectAll}
              style={{
                zIndex: 2,
              }}
              tabIndex={0}
              checked={selectedAll}
              className="owl-checkbox mr-8"
            />
          </th>
          {mappedHeaderProperties}
        </tr>
      </thead>
    </>
  );
};

export default TableHeader;
