import { Dropdown, Navbar } from "flowbite-react";
import { useLoadedProducts } from "../../../stores/useLoadedProducts";
import { useState } from "react";
import { useLoadedUser } from "../../../stores/useUserLoaded";

const topNavBarTheme = {
  root: {
    inner: {
      base: "mx-auto flex flex-wrap items-center justify-start",
    },
  },
};

function TopNav() {
  const oldBarnDashBoard = `${process.env.REACT_APP_BARN_API_URL}`;
  const barnEnvironment = `${process.env.REACT_APP_ENVIRONMENT}`;
  const [navColor] = useState(() => {
    if (barnEnvironment === "staging") {
      return "bg-stagingNavBar";
    }
    if (barnEnvironment === "dev") {
      return "bg-devNavBar";
    }
    return "bg-gray-600";
  });
  const actionDropDownItems = [
    { href: `${oldBarnDashBoard}/products/new`, label: "Add Product" },
    { href: `${oldBarnDashBoard}/features`, label: "Manage Features" },
    { href: `${oldBarnDashBoard}/otas`, label: "See OTAs" },
  ];
  const { productsData } = useLoadedProducts();
  const { userData } = useLoadedUser();

  return (
    <Navbar
      fluid
      className={`h-[60px] ${navColor} pt-4 text-white `}
      theme={topNavBarTheme}
    >
      <span className="flex flex-row text-xl uppercase">
        <a className={`hover:text-greenDisplay`} href={`${oldBarnDashBoard}`}>
          BARN
        </a>
      </span>

      <Navbar.Collapse className={"ml-8"}>
        <span className="flex flex-row">
          <a className={`hover:text-greenDisplay`} href={`${oldBarnDashBoard}`}>
            All products
          </a>
        </span>

        {userData && !userData.isPartner ? (
          <Dropdown
            inline
            label="Actions"
            placement="bottom"
            dismissOnClick={false}
            className={"mr-8"}
          >
            {actionDropDownItems?.map((item) => (
              <Dropdown.Item href={item.href}>{item.label}</Dropdown.Item>
            ))}
          </Dropdown>
        ) : (
          ""
        )}

        <Dropdown
          inline
          label="Products overview"
          placement="bottom"
          dismissOnClick={false}
          className={"max-h-64 overflow-y-auto"}
        >
          {productsData &&
            productsData.map((product) => {
              if (product.isAllProducts) {
                return "";
              }
              return (
                <Dropdown.Item
                  key={product.id}
                  href={`${oldBarnDashBoard}/products/${product.sku}/overview`}
                >
                  {product.name}
                </Dropdown.Item>
              );
            })}
        </Dropdown>
        <Navbar.Link
          className={"absolute right-8 text-white"}
          href={`${oldBarnDashBoard}/logout`}
        >
          Log out
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default TopNav;
