import { useSearch } from "../../../context";
import { useLoadedProducts } from "../../../../../stores/useLoadedProducts";
import { Dropdown } from "flowbite-react";
import { useQueryParams } from "../../../hooks/useQueryParams";

const channelsSelectionDropdownTheme = {
  arrowIcon: "absolute right-6 ",
  header: "text-xl",
  floating: {
    target:
      "min-w-[14rem] text-xl justify-start bg-transparent border-black text-black hover:!bg-transparent rounded-none focus:ring-0  max-h-64 overflow-y-auto",
  },
};
const ChannelDropdown = () => {
  const { setSelectedProduct, selectedProduct } = useSearch();
  const { productsData } = useLoadedProducts();
  const { setQueryParam } = useQueryParams();

  const defaultProductDisplay = (product, index) => {
    return (
      <Dropdown.Item
        key={`${product.name}-${index}`}
        onClick={() => {
          setSelectedProduct(product);
          setQueryParam("productSku", product.sku || null);
          setQueryParam("channel", null);
        }}
      >
        {product.name}
      </Dropdown.Item>
    );
  };

  const mappedProducts = productsData?.map((product, index) => {
    const isAllProducts = product.isAllProducts;

    return isAllProducts ? (
      <>
        {defaultProductDisplay(product, index)}
        <hr className="border-t-2" />
      </>
    ) : (
      defaultProductDisplay(product, index)
    );
  });

  return (
    <Dropdown
      label={selectedProduct?.name || "All Products"}
      className={"max-h-64 min-w-[14rem] overflow-y-auto"}
      theme={channelsSelectionDropdownTheme}
    >
      {mappedProducts}
    </Dropdown>
  );
};

export default ChannelDropdown;
