import { resourceSchemaDefault } from "axios-rest-resource";
import resourceBuilder from "../base";

const { read } = resourceSchemaDefault;

export const devicesResource = resourceBuilder.build("/devices", {
  read: {
    ...read,
  },
  exportCsv: {
    method: "get",
    url: "/devices/exportCsv",
  },
  deleteBatch: {
    method: "post",
    url: "/deleteBatch",
  },
});
