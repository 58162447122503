import { Input } from "reactstrap";
import React from "react";
import { defaultDevicesSearchProperties } from "../../../../data";
import moment from "moment";
import { useSearch } from "../../../../context";

export const TableRow = ({ device, index }) => {
  const { selectedDevices, setSelectedDevices } = useSearch();
  const isCurrentDeviceSelected = selectedDevices.includes(device.uuid);
  const handleRowSelectionChange = (deviceId) => {
    setSelectedDevices((prev) => {
      return prev.includes(deviceId)
        ? prev.filter((id) => id !== deviceId)
        : [...prev, deviceId];
    });
  };

  const convertDisplayNameWithType = (propertyNameDisplay, type) => {
    if (type === "date") {
      const dateMoment = moment.utc(propertyNameDisplay);
      if (!dateMoment.isValid()) {
        return propertyNameDisplay;
      }
      return dateMoment.format("MM/DD/YYYY hh:mm a").toUpperCase();
    }
    if (type === "redirect" || type === "redirectSoftware") {
      const redirectUrl = `${process.env.REACT_APP_BARN_API_URL}/products/${device.sku}/devices/${device.uuid}`;
      return (
        <a
          href={redirectUrl}
          className="cursor-pointer text-greenDisplay underline"
          target="_blank"
          rel="noreferrer"
        >
          {propertyNameDisplay}
        </a>
      );
    }
    if (type === "status") {
      let colorDisplay = "";
      if (propertyNameDisplay === "Active") {
        colorDisplay = "text-green-600";
      }
      if (propertyNameDisplay === "Requires Update") {
        colorDisplay = "text-red-600";
      }
      return <span className={colorDisplay}>{propertyNameDisplay}</span>;
    }
    return propertyNameDisplay;
  };
  const mapPropertyNameToDisplayName = () => {
    return defaultDevicesSearchProperties.map(
      ({ propertyName, type }, index) => {
        const propertyDisplayName = convertDisplayNameWithType(
          device[propertyName],
          type
        );
        return (
          <td
            style={{ fontFamily: "Roobert-regular" }}
            className="font-sans_semibold ml-8 block table-cell w-[15%] min-w-[120px]  cursor-default text-nowrap break-words border-t-0 pb-1 pl-3 pr-1 pt-1 text-left lg:w-[25%]"
            key={`${propertyName}-${device.id}-${index}`}
          >
            <span className={"text-left"}>{propertyDisplayName}</span>
          </td>
        );
      }
    );
  };

  return (
    <tr
      className={`${index % 2 === 0 ? "bg-gray-100" : "bg-white"} h-[60px] ${
        isCurrentDeviceSelected ? "border-2 border-greenDisplay" : ""
      }`}
      key={index}
    >
      <td className="check-device hidden border-t-0 pl-2 pr-0 md:table-cell md:w-[3.5%]">
        <Input
          id={`selected-${index}`}
          key={`input-${index}`}
          name="selectAll"
          type="checkbox"
          onChange={(e) =>
            handleRowSelectionChange(device.uuid, e.target.checked)
          }
          style={{ zIndex: 2 }}
          tabIndex={0}
          checked={selectedDevices.includes(device.uuid)}
          className="owl-checkbox"
        />
      </td>
      {mapPropertyNameToDisplayName()}
    </tr>
  );
};
