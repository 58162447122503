import { useCallback, useRef } from "react";

export const useDebounce = (fn, delay) => {
  // Use useRef to store the timeoutId without triggering re-renders
  const timeoutIdRef = useRef(null);
  return useCallback(
    (...args) => {
      // Clear the previous timeout, if there is one
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      // Set a new timeout
      timeoutIdRef.current = setTimeout(() => {
        fn(...args);
      }, delay);
    },
    [fn, delay]
  );
};
