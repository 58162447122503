import { useState } from "react";

export const useQueryParams = () => {
  const [queryParams, setQueryParams] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of urlParams.entries()) {
      params[key] = value;
    }
    return params;
  });

  const setQueryParam = (paramName, paramValue) => {
    const urlParams = new URLSearchParams(window.location.search);
    const newQueryParams = { ...queryParams };

    if (paramValue) {
      urlParams.set(paramName, paramValue);
      newQueryParams[paramName] = paramValue;
    } else {
      urlParams.delete(paramName);
      delete newQueryParams[paramName];
    }

    let newUrl = window.location.pathname;
    if (Array.from(urlParams).length > 0) {
      newUrl += `?${urlParams.toString()}`;
    }
    window.history.pushState({ path: newUrl }, "", newUrl);

    setQueryParams(newQueryParams);
  };

  const clearAllQueryParams = () => {
    const newUrl = window.location.pathname;
    window.history.pushState({ path: newUrl }, "", newUrl);
    setQueryParams({});
  };

  return { queryParams, setQueryParam, clearAllQueryParams };
};
