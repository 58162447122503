import "./App.css";
import {
  MemoryRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { DevicesSearch } from "./views/DevicesSearch";
import { LocaleProvider } from "./lang";
import { useEffect } from "react";
import { useLoadedUser } from "./stores/useUserLoaded";

function LoadUserAfterRedirect() {
  const location = useLocation();
  const { fetchUserData } = useLoadedUser();

  useEffect(() => {
    fetchUserData();
    document.documentElement.classList.remove("dark");
    localStorage.theme = "light";
  }, [location, fetchUserData]);

  return null;
}

function App() {
  return (
    <LocaleProvider>
      <Router>
        <LoadUserAfterRedirect />
        <Routes>
          <Route path="/" element={<DevicesSearch />} />
        </Routes>
      </Router>
    </LocaleProvider>
  );
}

export default App;
