import React, { createContext, useContext, useState, useEffect } from "react";
import { IntlProvider } from "react-intl";

// Assuming enLang and esLang are objects containing localized messages
import enLang from "./entries/en-US";
import esLang from "./entries/es-ES";

const AppLocale = {
  en: enLang,
  es: esLang,
};

const LocaleContext = createContext();

export const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState(AppLocale[locale].messages);

  useEffect(() => {
    if (AppLocale[locale]) {
      setMessages(AppLocale[locale].messages);
    }
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider locale={locale} messages={messages} defaultLocale="en">
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export const useLocale = () => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error("useLocale must be used within a LocaleProvider");
  }
  return context;
};
