import TableHeader from "./TableHeader/TableHeader";
import TableBody from "./TableBody/TableBody";
import { useSearch } from "../../context";

export function DevicesSearchTable() {
  const { devices, isLoading } = useSearch();

  const displayNoDevicesFound = () => {
    return (
      <div className="flex w-full items-center justify-center pt-4 text-2xl">
        <p>No devices found</p>
      </div>
    );
  };

  const displayDevices = () => {
    return (
      <div className=" flex-column inline-flex w-full items-center justify-start ">
        <table className="table-striped mb-0 block table w-full md:min-w-[1052px]">
          <TableHeader />
          <TableBody />
        </table>
      </div>
    );
  };

  const shouldDisplayNoDevicesFound = () => {
    return !devices || (devices?.length <= 0 && !isLoading);
  };
  return (
    <>
      <div className="mt-5 h-[70vh] w-full overflow-x-auto overflow-y-auto rounded-[8px] bg-white  p-2 pt-0">
        {shouldDisplayNoDevicesFound()
          ? displayNoDevicesFound()
          : displayDevices()}
      </div>
    </>
  );
}
