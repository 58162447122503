import { useEffect, useRef, useState } from "react";
import { devicesResource } from "../../../../resources";
import { useQueryParams } from "../useQueryParams";

export function useDevices(
  searchTerm,
  selectedChannel,
  selectedPage,
  selectedProduct,
  setSelectedPage,
  setSelectedDevices,
  setSelectedChannel
) {
  const [devices, setDevices] = useState([]);
  const [totalDevices, setTotalDevices] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [totalNumOfPages, setTotalNumOfPages] = useState(0);
  const previousSearchTerm = useRef(searchTerm);
  const previousPage = useRef(selectedPage);
  const previousChannelId = useRef(selectedChannel?.id);
  const previousProduct = useRef(selectedProduct?.id);
  const { queryParams } = useQueryParams();

  const fetchDevices = async () => {
    const fetchParams = {
      params: {
        query: searchTerm,
        channel: selectedChannel?.id,
        page: selectedPage,
        productId: selectedProduct?.id,
      },
    };
    setIsLoading(true);
    setSelectedDevices([]);
    try {
      const loadedDevices = await devicesResource.read(fetchParams);
      const totalNumOfDevices = loadedDevices?.deviceCount;
      setDevices(loadedDevices?.devices);
      setTotalDevices(totalNumOfDevices);
      const totalNumOfPages = totalNumOfDevices
        ? Math.ceil(loadedDevices.deviceCount / 100)
        : 0;
      setTotalNumOfPages(totalNumOfPages);
    } catch (error) {
      console.error("Error loading devices", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateRefs = () => {
    previousSearchTerm.current = searchTerm;
    previousChannelId.current = selectedChannel?.id;
    previousProduct.current = selectedProduct?.id;
    previousPage.current = selectedPage;
  };

  useEffect(() => {
    const isSearchTermChanged = searchTerm !== previousSearchTerm.current;
    const isChannelChanged = selectedChannel?.id !== previousChannelId.current;
    const isProductChanged = selectedProduct?.id !== previousProduct.current;
    const isPageChanged = selectedPage !== previousPage.current;
    const anyChangeOccurred =
      isSearchTermChanged ||
      isChannelChanged ||
      isProductChanged ||
      isPageChanged;

    if (anyChangeOccurred) {
      const searchChannelOrProductChanged =
        isSearchTermChanged || isChannelChanged || isProductChanged;
      const productChangedWithChannel = isProductChanged && !!selectedChannel;
      if (searchChannelOrProductChanged && productChangedWithChannel) {
        setSelectedPage(0);
        setSelectedChannel(null);
      } else if (searchChannelOrProductChanged && selectedPage !== 0) {
        setSelectedPage(0);
      } else if (productChangedWithChannel) {
        setSelectedChannel(null);
      } else {
        fetchDevices();
      }
      updateRefs();
      return;
    }
    if (
      (!devices || devices.length === 0) &&
      (Object.keys(queryParams)?.length === 0 || queryParams.searchTerm)
    ) {
      fetchDevices();
    }
    // eslint-disable-next-line
  }, [
    searchTerm,
    selectedChannel?.id,
    selectedPage,
    selectedProduct,
    setSelectedChannel,
    setSelectedPage,
    setSelectedDevices,
  ]);

  return {
    devices,
    isLoading,
    totalNumOfPages,
    fetchDevices,
    setIsLoading,
    totalDevices,
  };
}
