/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

const weWerentAbleTo = (message) =>
  `¡Caramba! No fue posible ${message}. Por favor pruébalo más tarde.`;

module.exports = {
  /* 01.General */

  /* 02.Inicio de sesión de usuario, cierre de sesión, registro */
  "user.login-title": "Iniciar sesión",
  "user.register": "Registro",
  "user.forgot-password": "Se te olvidó tu contraseña",
  "user.email": "Email",
  "user.password": "Contraseña",
  "user.forgot-password-question": "¿Contraseña olvidada?",
  "user.fullname": "Nombre completo",
  "user.login-button": "INICIAR SESIÓN",
  "user.register-button": "REGISTRO",
  "user.reset-password-button": "REINICIAR",
  "user.buy": "COMPRAR",
  "user.username": "Nombre de Usuario",

  /* 03.Menú */
  "menu.app": "Inicio",
  "menu.dashboards": "Tableros",
  "menu.gogo": "Gogo",
  "menu.start": "Comienzo",
  "menu.second-menu": "Segundo menú",
  "menu.second": "Segundo",
  "menu.ui": "IU",
  "menu.charts": "Gráficos",
  "menu.chat": "Chatea",
  "menu.survey": "Encuesta",
  "menu.todo": "Notas",
  "menu.search": "Búsqueda",
  "menu.docs": "Docs",
  "menu.blank-page": "Blank Page",

  /* 04.Error  */
  "pages.error-title": "Vaya, parece que ha ocurrido un error!",
  "pages.error-code": "Código de error",
  "pages.go-back-home": "REGRESAR A INICIO",

  /* 06.Devices Page */
  "devices.buttons.export": "Exportar",
  "device.success.name-update": "El nombre fue cambiado con éxito.",
  "device.error.name-update": weWerentAbleTo("cambiar el nombre"),
  "device.location.label": "Ubicación",
  "device.location.all": "Todas",
  "device.info.software-upgrade-available":
    "Hay actualización de software desponible",

  /* 09. Activate device page. */
  "activate-device.form.title": "¡Bienvenido!",
  "activate-device.form.subtitle": "Inscripción de dispositivo",
  "activate-device.form.enter-code":
    "Ingresa el código de activación del nuevo dispositivo.",
  "activate-device.form.enter-code-placeholder": "Código de activación",
  "activate-device.form.pair": "Empareja el Owl conectado",
  "activate-device.form.submit": "Activar",
  "activate-device.form.error":
    "Por favor ingresa un código de activación válido",
  "activate-device.login.title":
    "Para activar y configurar tu nuevo dispositivo por favor inicia una sesión",
  "activate-device.toast.success":
    "Inicialización del dispositivo ha empezado.",
  "activate-device.wizard.device-setup": "Configuración del dispositivo",
  "activate-device.wizard.device-setup.site.title":
    "Añadir el sitio de tu nuevo dispositivo",
  "activate-device.wizard.device-setup.site.description":
    "Para mantener tus Owls organizados en el portal de Nest, primero provee el sitio de tu nuevo dispositivo.",
  "activate-device.wizard.device-setup.site.prompt":
    "Por favor ingresa o busca el sitio de su oficina",
  "activate-device.wizard.device-setup.site.placeholder":
    "Busca el sitio o ingresa uno nuevo",
  "activate-device.wizard.device-setup.room.title":
    "Añadir el nombre de la sala para tu nuevo dispositivo",
  "activate-device.wizard.device-setup.room.description":
    "También por favor provee el sitio de tu nuevo dispositivo.",
  "activate-device.wizard.device-setup.room.prompt":
    "Por favor ingresa o busca el nombre de la sala en este sitio",
  "activate-device.wizard.device-setup.room.placeholder":
    "Busca el sitio o ingresa uno nuevo",
  "activate-device.wizard.device-setup.setup-later": "Configurar más tarde",
  "activate-device.wizard.device-setup.submit": "Enviar",
  "activate-device.wizard.setup-completed": "¡Configuración completa!",
  "activate-device.wizard.setup-completed.description":
    "¡Gracias por completar configuración de Nest! Por favor vuelve al dispositivo para integrar Zoom.",
  "activate-device.wizard.setup-completed.button": "Cerrar",
  "activate-device.wizard.skip-setup": "¿Listo para salir?",
  "activate-device.wizard.skip-setup.description":
    "Necesitarás completar la configuración en el portal Nest para poder usar todas las funciones de tu dispositivo.",
  "activate-device.wizard.skip-setup.close-button": "Configurar más tarde",
  "activate-device.wizard.skip-setup.set-up-now": "Configurar ahora",
};
