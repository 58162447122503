import { Pagination as Paginate } from "flowbite-react";
import { useSearch } from "../../../context";

export function Pagination() {
  const {
    totalNumOfPages,
    setSelectedPage,
    selectedPage,
    totalDevices,
    devices,
  } = useSearch();
  const actualPage = selectedPage + 1;
  const actualTotalPages = totalNumOfPages;
  const displayedTotalDevices = !selectedPage ? 1 : selectedPage * 100 + 1;
  const totalEndCounter = devices?.length
    ? selectedPage * 100 + devices.length
    : 0;

  const selectPage = (selectedPage) => {
    setSelectedPage(selectedPage - 1);
  };

  return (
    <div className="flex flex-col items-center justify-start space-y-4">
      {totalNumOfPages ? (
        <>
          <Paginate
            layout={"pagination"}
            currentPage={actualPage}
            totalPages={actualTotalPages}
            onPageChange={selectPage}
          />
          <div className="!mt-2 text-center">
            {" "}
            {`Showing ${displayedTotalDevices} to ${totalEndCounter} out of ${totalDevices}`}{" "}
          </div>
        </>
      ) : null}
    </div>
  );
}
