import { ResourceBuilder } from "axios-rest-resource";

const resourceBuilder = new ResourceBuilder({
  baseURL: `${process.env.REACT_APP_BARN_API_URL}`,
  withCredentials: true,
});

const handleApiRedirect = (response) => {
  window.location = `${process.env.REACT_APP_BARN_API_URL}`;
};

const successInterceptor = (response) => {
  return response.data;
};

resourceBuilder.axiosInstance.interceptors.response.use(
  successInterceptor,
  handleApiRedirect
);

export default resourceBuilder;
