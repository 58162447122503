import { create } from "zustand";
import { agentResources } from "../../resources";
export const useLoadedUser = create((set, state) => ({
  userData: {},
  fetchUserData: async () => {
    try {
      const loadedUser = await agentResources.read();
      set({ userData: loadedUser });
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  },
}));
