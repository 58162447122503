import { useSearch } from "../../../context";
import { Dropdown } from "flowbite-react";
import { useMemo } from "react";
import { useQueryParams } from "../../../hooks/useQueryParams";

const channelsSelectionDropdownTheme = {
  arrowIcon: "absolute right-6",
  floating: {
    target:
      "min-w-[14rem] bg-transparent justify-start border-black text-black hover:!bg-transparent rounded-none focus:ring-0 ml-4 max-h-64 overflow-y-auto",
  },
};
const ChannelDropdown = () => {
  const { setQueryParam } = useQueryParams();
  const { channels, setSelectedChannel, selectedChannel, selectedProduct } =
    useSearch();

  const defaultChannelDisplay = (channel, index, isAllChannels) => {
    return (
      <Dropdown.Item
        key={`${channel?.id}-${index}`}
        onClick={() => {
          setSelectedChannel(isAllChannels ? null : channel);
          setQueryParam("channel", channel.id || null);
        }}
      >
        {channel.slug}
      </Dropdown.Item>
    );
  };

  const mappedChannels = useMemo(() => {
    return channels?.map((channel, index) => {
      if (
        !channel?.isAllChannels &&
        selectedProduct &&
        selectedProduct.id !== channel.ProductId
      )
        return null;
      const isAllChannels = channel.isAllChannels;
      return isAllChannels ? (
        <>
          {defaultChannelDisplay(channel, index, isAllChannels)}
          <hr className="border-t-2" />
        </>
      ) : (
        defaultChannelDisplay(channel, index, isAllChannels)
      );
    });
    // eslint-disable-next-line
  }, [selectedProduct, channels]);

  return (
    <div>
      <Dropdown
        label={selectedChannel?.slug || "All Channels"}
        disabled={!selectedProduct?.id}
        theme={channelsSelectionDropdownTheme}
        className={`max-h-64 overflow-y-auto`}
      >
        {mappedChannels}
      </Dropdown>
    </div>
  );
};

export default ChannelDropdown;
