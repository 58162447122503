import { useSearch } from "../../../context";

export function SelectedCounter() {
  const { selectedDevices } = useSearch();
  const totalSelectedDevices = selectedDevices?.length;
  if (!totalSelectedDevices) {
    return null;
  }

  return (
    <section className="mr-4 mt-1 bg-gray-100 pb-3">
      <span
        className={"font-bold text-greenDisplay"}
      >{`${totalSelectedDevices} selected`}</span>
    </section>
  );
}
