import React from "react";
import { useSearch } from "../../../context";
import { OwlSpinner } from "@owl-labs/ui-components";
import { TableRow } from "./TableRow/TableRow";

const TableBody = () => {
  const { devices, isLoading } = useSearch();
  const mappedSearchData = devices?.map((device, index) => (
    <TableRow key={`${device.id}-${index}`} device={device} index={index} />
  ));

  return (
    <tbody>
      {isLoading ? (
        <tr>
          <td>
            <OwlSpinner
              size="large"
              color="blue"
              additionalClassNames="fixed left-[calc(45%-15px)] top-[calc(30%-15px)] z-10 inline-block w-[88px] h-[88px] border-[6px]"
            />
          </td>
        </tr>
      ) : (
        mappedSearchData
      )}
    </tbody>
  );
};

export default TableBody;
