export const defaultDevicesSearchProperties = [
  { propertyName: "id", propertyDisplayName: "ID" },
  { propertyName: "productName", propertyDisplayName: "Product" },
  { propertyName: "name", propertyDisplayName: "Name" },
  { propertyName: "alias", propertyDisplayName: "Alias" },
  {
    propertyName: "serial",
    propertyDisplayName: "SW Serial",
    type: "redirectSoftware",
  },
  { propertyName: "uuid", propertyDisplayName: "UUID", type: "redirect" },
  { propertyName: "hardwareSerial", propertyDisplayName: "HW Serial" },
  { propertyName: "softwareVersionString", propertyDisplayName: "SW Version" },
  { propertyName: "channel", propertyDisplayName: "Channel" },
  { propertyName: "warrantyStatus", propertyDisplayName: "Warranty" },
  {
    propertyName: "warrantyExpirationDate",
    propertyDisplayName: "Warranty expiration Date",
  },
  { propertyName: "statusText", propertyDisplayName: "Status", type: "status" },
  { propertyName: "owner", propertyDisplayName: "Owner" },
  { propertyName: "dSKU", propertyDisplayName: "dSKU" },
  {
    propertyName: "checkedInAt",
    propertyDisplayName: "Last Check In",
    type: "date",
  },
  { propertyName: "lastGeo", propertyDisplayName: "Last Geo" },
  { propertyName: "secureDevice", propertyDisplayName: "Secure Device" },
];
